import React, { Component } from "react";
import { produce } from "immer";
import { Alert } from "react-bootstrap";
import { Dropdown } from "../../shared/v1";
import DatePicker from "../../shared/DatePicker";
import { updatePersonPromise } from "../actions/updatePerson";
import IconButton from "@shared/v2/IconButton";
import { PencilSolidV6, XmarkSolidV6 } from "@shared/v2/Icomoon";
import TextInput from "@shared/v2/TextInput";
import Button from "@shared/v2/Button";

const PERSON_DETAILS_TEXT_CSS = "tw-whitespace-pre-wrap tw-break-all tw-text-14d tw-text-gray-75";

class EditableElement extends Component {
  state = {
    editingElement: false,
    editablePerson: JSON.parse(JSON.stringify(this.props.person)),
    errors: null,
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.person !== nextProps.person) {
      this.setState({ editablePerson: JSON.parse(JSON.stringify(nextProps.person)) });
    }
  }

  onElementEdit = () => {
    this.setState({ editingElement: true });
  };

  onElementSave = () => {
    updatePersonPromise(this.state.editablePerson)
      .then((r) => {
        this.props.updatePersonData(r.data);
        this.setState({ editingElement: false, errors: null });
      })
      .catch((err) => {
        this.setState({ errors: err.response.data.errors });
      });
  };

  onDismissElementEdit = () => {
    this.setState({
      editingElement: false,
      editablePerson: JSON.parse(JSON.stringify(this.props.person)),
      errors: null,
    });
  };

  handleEditableValueChange = (value, key) => {
    this.setState(({ editablePerson }) => ({
      editablePerson: produce(editablePerson, (draft) => {
        draft.data.attributes[key] = value;
      }),
    }));
  };

  handleCustomFieldChange = (key, value) => {
    this.setState(({ editablePerson }) => ({
      editablePerson: produce(editablePerson, (draft) => {
        draft.data.attributes.custom_fields[key] = value;
      }),
    }));
  };

  handleDetailableValueChange = (value, key) => {
    this.setState(({ editablePerson }) => ({
      editablePerson: produce(editablePerson, (draft) => {
        draft.data.attributes[key].value = value;
      }),
    }));
  };

  formatSelectOptions = (options) => {
    return options.map((opt) => {
      return { label: opt[0], value: opt[1] };
    });
  };

  handleMilestoneableDateChange = (value, key) => {
    this.setState(({ editablePerson }) => ({
      editablePerson: produce(editablePerson, (draft) => {
        draft.data.attributes[key].date = value;
      }),
    }));
  };

  editMultilineInput = () => {
    return (
      <TextInput
        className="tw-w-full"
        value={this.state.editablePerson.data.attributes[this.props.elementType]}
        onChange={(e) => this.handleEditableValueChange(e.target.value, this.props.elementType)}
        multiline
        rows={5}
      />
    );
  };

  editSingleLineInputInput = () => {
    return (
      <TextInput
        containerClassName="tw-w-full"
        size={1}
        value={this.state.editablePerson.data.attributes[this.props.elementType]}
        onChange={(e) => this.handleEditableValueChange(e.target.value, this.props.elementType)}
      />
    );
  };

  editCustomFieldInput = () => {
    return (
      <TextInput
        containerClassName="tw-w-full"
        size={1}
        value={this.state.editablePerson.data.attributes.custom_fields[this.props.fieldName]}
        onChange={(e) => this.handleCustomFieldChange(this.props.fieldName, e.target.value)}
      />
    );
  };

  editDetailableSingleLineInputInput = () => {
    return (
      <TextInput
        containerClassName="tw-w-full"
        size={1}
        value={this.state.editablePerson.data.attributes[this.props.elementType].value}
        onChange={(e) => this.handleDetailableValueChange(e.target.value, this.props.elementType)}
      />
    );
  };

  editDropdownInput = () => {
    return (
      <Dropdown
        isSearchable={false}
        isClearable={false}
        value={this.state.editablePerson.data.attributes[this.props.elementType].value}
        onChange={(e) => this.handleDetailableValueChange(e.value, this.props.elementType)}
        placeholder="Select..."
        options={this.formatSelectOptions(this.state.editablePerson.meta[this.props.dropdownOptions])}
        variant="flatBlueishGray"
      />
    );
  };

  editDateInput = () => {
    return (
      <DatePicker
        timeFormat={false}
        dateFormat="YYYY-MM-DD"
        name={this.props.elementType}
        value={this.state.editablePerson.data.attributes[this.props.elementType].date}
        onChange={(value) => this.handleMilestoneableDateChange(value, this.props.elementType)}
      />
    );
  };

  renderEditFieldsByType = () => {
    switch (this.props.elementType) {
      case "description":
        return this.editMultilineInput();
      case "letter_salutation":
        return this.editSingleLineInputInput();
      case "envelope_salutation":
        return this.editSingleLineInputInput();
      case "marital_status":
        return this.editDropdownInput();
      case "kids":
        return this.editDetailableSingleLineInputInput();
      case "pets":
        return this.editDetailableSingleLineInputInput();
      case "timezone":
        return this.editDropdownInput();
      case "company":
        return this.editDetailableSingleLineInputInput();
      case "job_title":
        return this.editDetailableSingleLineInputInput();
      case "custom_field":
        return this.editCustomFieldInput();
      default:
        return undefined;
    }
  };

  renderEditFields = () => {
    return (
      <div>
        {this.state.errors && (
          <Alert bsStyle="danger">
            {this.state.errors.map((error) => (
              <span>
                <strong>Error: </strong> {error}
                <br />
              </span>
            ))}
          </Alert>
        )}

        <div className="tw-flex tw-gap-[8px] tw-mt-[8px]">
          <div className="tw-flex-1">{this.renderEditFieldsByType()}</div>
          <div className="tw-flex tw-gap-[4px]">
            <Button onClick={this.onElementSave}>Save</Button>
            <IconButton schema="tertiary" size="small" onClick={this.onDismissElementEdit}>
              <XmarkSolidV6 />
            </IconButton>
          </div>
        </div>
      </div>
    );
  };

  renderFieldValues = () => {
    switch (this.props.elementType) {
      case "description":
        return (
          <div
            className={PERSON_DETAILS_TEXT_CSS}
            dangerouslySetInnerHTML={{ __html: this.props.person.data?.attributes.description }}
          />
        );
      case "envelope_salutation":
        return (
          <div className={PERSON_DETAILS_TEXT_CSS}>
            {this.props.person.data?.attributes.envelope_salutation}
          </div>
        );
      case "letter_salutation":
        return (
          <div className={PERSON_DETAILS_TEXT_CSS}>
            {this.props.person.data?.attributes.letter_salutation}
          </div>
        );
      case "marital_status":
        return (
          <div className={PERSON_DETAILS_TEXT_CSS}>
            {this.props.person.data?.attributes.marital_status?.value}
          </div>
        );
      case "kids":
        return (
          <div className={PERSON_DETAILS_TEXT_CSS}>{this.props.person.data?.attributes.kids?.value}</div>
        );
      case "pets":
        return (
          <div className={PERSON_DETAILS_TEXT_CSS}>{this.props.person.data?.attributes.pets?.value}</div>
        );
      case "timezone":
        return (
          <div className={PERSON_DETAILS_TEXT_CSS}>{this.props.person.data?.attributes.timezone?.value}</div>
        );
      case "company":
        return (
          <div className={PERSON_DETAILS_TEXT_CSS}>{this.props.person.data?.attributes.company?.value}</div>
        );
      case "job_title":
        return (
          <div className={PERSON_DETAILS_TEXT_CSS}>{this.props.person.data?.attributes.job_title?.value}</div>
        );
      case "custom_field":
        return (
          <div className={PERSON_DETAILS_TEXT_CSS}>
            {this.props.person.data?.attributes.custom_fields[this.props.fieldName]}
          </div>
        );
      default:
        return;
    }
  };

  render() {
    return (
      <div className="tw-group tw-px-[20px] tw-py-[8px] hover:tw-bg-gray-5">
        <div className="tw-flex tw-justify-between">
          <div className="tw-text-11d tw-text-gray-75 tw-font-bold tw-uppercase">{this.props.fieldName}</div>
          {!this.state.editingElement && (
            <IconButton
              className="tw-invisible group-hover:tw-visible"
              size="small"
              onClick={this.onElementEdit}
            >
              <PencilSolidV6 />
            </IconButton>
          )}
        </div>
        <div>{this.state.editingElement ? this.renderEditFields() : this.renderFieldValues()}</div>
      </div>
    );
  }
}

export default EditableElement;
