import React from "react";
import { useDispatch } from "react-redux";
import Alert from "@shared/v2/Alert";
import ContactCard from "./ContactCard";
import ContactContainer from "./ContactContainer";
import {
  useContactMergeOtherContact,
  useContactMergePotentialDuplicates,
  useContactMergePrimaryContact,
} from "../reducers/contactMergeReducer/selectors";
import * as actions from "../reducers/contactMergeReducer";

const ChooseContact = () => {
  const dispatch = useDispatch();
  const potentialDuplicates = useContactMergePotentialDuplicates();
  const primaryContact = useContactMergePrimaryContact();
  const otherContact = useContactMergeOtherContact();

  return (
    <div className="tw-flex tw-flex-col tw-gap-[24px] tw-overflow-hidden">
      <p className="tw-m-0 tw-text-gray-50">
        Select a second lead to initiate the merge process. Leads with an identical mobile phone number or
        email address to the lead on the left will be suggested for merging.
      </p>
      <div className="tw-flex tw-gap-[12px] tw-items-start tw-overflow-hidden">
        <ContactContainer title="Merge this lead">
          <ContactCard contact={primaryContact} />
        </ContactContainer>
        <ContactContainer className="tw-self-stretch" title="With this lead">
          {potentialDuplicates.length === 0 && (
            <Alert
              variant="info"
              text="It appears there are no duplicate people.  Perhaps there is a ‘trash’ or ‘archived’ lead that has not been merged with the same contact information."
            />
          )}
          {potentialDuplicates.map((c) => (
            <ContactCard
              key={c.id}
              contact={c}
              showViewLink
              disabled={primaryContact.hasListings && c.hasListings}
              onSelect={(oc) => dispatch(actions.setOtherContact(oc))}
              selected={c.id === otherContact?.id}
            />
          ))}
        </ContactContainer>
      </div>
    </div>
  );
};

export default ChooseContact;
