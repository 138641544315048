import React from "react";

import { ListCheckSolidV6 } from "@shared/v2/Icomoon";

import ActivitiesContainer from "./ActivitiesContainer";
import { activityCommonDefaultProps, activityCommonPropTypes, STYLES } from "../shared";
import PipedElements from "../PipedElements";

const NewTaskAcitivity = ({ activity, onReadChange }) => {
  const { taskType, taskTitle, taskDueDate, assignerName } = activity.data;
  return (
    <ActivitiesContainer
      activity={activity}
      onReadChange={onReadChange}
      Icon={ListCheckSolidV6}
      title={activity.title}
    >
      <div className={STYLES.contentContainer}>
        <span>{taskTitle}</span>
        <span>Type: {taskType}</span>
        <PipedElements>
          <span>Due: {taskDueDate}</span>
          <span>From: {assignerName}</span>
        </PipedElements>
      </div>
    </ActivitiesContainer>
  );
};

NewTaskAcitivity.propTypes = {
  ...activityCommonPropTypes,
};

NewTaskAcitivity.defaultProps = {
  ...activityCommonDefaultProps,
};

export default NewTaskAcitivity;
