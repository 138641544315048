import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import Proptypes from "prop-types";
import { requestDataToSnakeCase } from "@shared/v2/caseTransformingAxios";
import Tooltip from "@shared/v2/Tooltip";
import IconButton from "@shared/v2/IconButton";
import { PhoneSolidV6 } from "@shared/v2/Icomoon";
import SmsModalButton from "../../SmsModal/components/SmsModalButton";
import TaskFormLogAppointment from "../../Tasks/components/TaskFormLogAppointment";
import OtherInteract from "../../People/components/OtherInteract";
import LastInteractions from "./LastInteractions";
import NewTask from "../../Tasks/components/NewTask";
import AgentOrPondSelect from "../../shared/AgentOrPondSelect";
import AssignedPlansPreviewApp from "../../AssignedPlans/startup/AssignedPlansPreviewApp";
import MilestoneDate from "./MilestoneDate";
import HomeAppLink from "../../HomeApp/components/HomeAppLink";
import FavoritesOrViews from "../../People/components/FavoritesOrViews";
import AllListingAlertPreview from "../../PersonDetail/components/listing_alerts/AllListingAlertPreview";
import MarketReportPreview from "../../MarketReports/MarketReportPreview";
import Status from "./Status";
import TagTooltip from "./TagTooltip";
import CMALink from "./CMALink";
import EmailInteraction from "./EmailInteraction";
import Intent from "./Intent";
import Name from "./Name";
import Address from "./Address";
import AddMarketReportModal from "../../AddMarketReportModal";
import { deleteMarketReportApi } from "../../shared/serviceHelpers";
import Stage from "./Stage";
import { viewPhoneFromFlat } from "../../shared/models/Communications/viewPhone";
import { setLeadUuid } from "../../reducers/dialerReducer/dialerReducer";

const personPrimaryPhone = (person) => {
  const phones = person.dialable_phone_numbers.map(viewPhoneFromFlat);
  return phones.find((phone) => phone.isPrimary) || phones[0];
};

const mapStateToProps = (state) => ({
  messagingServiceUrl: state.leadIndex.messagingServiceUrl,
  people: state.leadIndex.people,
  currentUser: state.leadIndex.currentUser,
  account: state.leadIndex.account,
  googleCalendarClientId: state.leadIndex.googleCalendarClientId,
  googleCalendarApiKey: state.leadIndex.googleCalendarApiKey,
  accountMilestoneNames: state.leadIndex.accountMilestoneNames,
  platformAccounts: state.leadIndex.platformAccounts,
  autoPlansFeature: state.leadIndex.autoPlansFeature,
  hotLeadsFeature: state.leadIndex.hotLeadsFeature,
  enableLeadPond: state.leadIndex.enableLeadPond,
  idxDataUri: state.leadIndex.idxDataUri,
  idxDataToken: state.leadIndex.idxDataToken,
  from: state.leadIndex.from,
  featureFlags: state.layout.featureFlags,
});

const LeadRows = ({
  setCallLeadUuid,
  messagingServiceUrl,
  people,
  currentUser,
  account,
  googleCalendarClientId,
  googleCalendarApiKey,
  accountMilestoneNames,
  platformAccounts,
  autoPlansFeature,
  hotLeadsFeature,
  enableLeadPond,
  idxDataUri,
  idxDataToken,
  from,
  featureFlags,
}) => {
  const hasIdxInfo = (person) => {
    if (
      (person.idx_property_views && person.idx_property_views !== 0) ||
      (person.idx_favorites && person.idx_favorites !== 0)
    ) {
      return true;
    }
    return false;
  };

  const widths = {
    minWidth: "210px",
    maxWidth: "210px",
  };

  const lastVisit = (person) => {
    let lastvisit = "—";
    if (person.person.idx_last_visit) {
      lastvisit = person.idx_last_visit_time;
    } else if (person.source && person.source === "Brivity IDX") {
      lastvisit = person.last_idx_visit_created_at;
    } else {
      lastvisit = "—";
    }
    return lastvisit;
  };
  const Visits = (person) => {
    let idxvisit = "—";
    if (person.person.idx_visits && person.person.idx_visits !== 0) {
      idxvisit = person.person.idx_visits;
    } else if (person.source && person.source === "Brivity IDX") {
      idxvisit = "1";
    } else {
      idxvisit = "—";
    }
    return idxvisit;
  };

  const getCheckedCheckboxesFor = () => document.querySelectorAll("input.multi-select:checked").length;

  const handleSelectRows = () => {
    const numberOfCheckedRows = getCheckedCheckboxesFor();
    if (numberOfCheckedRows === people.length) {
      // all on page
      document.getElementById("select-rows").checked = true;
      document.getElementById("select-rows").indeterminate = false;
    } else if (numberOfCheckedRows === 0) {
      // none selected
      document.getElementById("select-rows").checked = false;
      document.getElementById("select-rows").indeterminate = false;
    } else {
      // some are selected
      document.getElementById("select-rows").checked = false;
      document.getElementById("select-rows").indeterminate = true;
    }

    document.getElementById("is-all-selected").value = false;

    const leadSelectedEvent = new Event("lead-row:selected");
    window.dispatchEvent(leadSelectedEvent);
  };

  return (
    <tbody>
      {people.map((_person) => {
        const person = useMemo(
          () => ({
            ...requestDataToSnakeCase(_person),
            leadPond: _person.leadPond,
            primaryAgent: _person.primaryAgent,
          }),
          [_person],
        );
        const [showAddModal, setShowModal] = useState(false);
        const [editReportId, setEditId] = useState(false);
        const deleteMarketReport = async (id, primaryAgent) =>
          new Promise((resolve) =>
            deleteMarketReportApi({
              data: { id, primary_agent_uuid: primaryAgent },
              successCb: resolve,
              errorCb: () => resolve(false),
            }),
          );

        const setMarketModal = (open) => {
          setShowModal(open);
          setEditId(null);
        };
        const editMarketReport = (id) => {
          setShowModal(id !== null);
          setEditId(id);
        };
        const primaryPhone = personPrimaryPhone(person);

        return (
          <React.Fragment key={person.id}>
            <tr
              className="person partial"
              data-full-name={from !== "leads" ? person.name_or_no_name : ""}
              data-fetch_results_count={from === "leads" ? person.fetch_results_count : ""}
              data-searched={from === "leads" && person.lead_last_search_at !== null ? true : ""}
              data-lead-id={person.person_lead_id}
              data-object-id={person.person_id}
              data-person-id={person.person_id}
              data-person-uuid={person.person.uuid}
              data-primary-agent={person.primary_agent_id}
              data-primary-email={from !== "leads" ? person.primary_email_address : ""}
              key={person.person.id}
            >
              <td className="checkbox-col fixed-col">
                <div className="checkboxselect checkbox lead-checkbox">
                  {person.edit_permission && (
                    <>
                      <input
                        type="checkbox"
                        name={person.multi_select_id}
                        onChange={handleSelectRows}
                        id={person.multi_select_id}
                        value="1"
                        className="multi-select"
                      />
                      <label
                        aria-label={person.multi_select_id}
                        htmlFor={person.multi_select_id}
                        className="tw-pt-2px"
                      />
                    </>
                  )}
                </div>
              </td>
              <td className="name-search-result-count fixed-col" aria-label="name">
                <Name person={person} />
              </td>
              {from === "leads" ? (
                <td className="finish-lead">
                  {person.is_partial && (
                    <span className="create-person tw-cursor-pointer">
                      <i className="fa fa-user-plus" />
                    </span>
                  )}
                </td>
              ) : (
                <td className="display-in-leads">
                  <div className="display-in-lead">
                    <i
                      aria-label="display in leads"
                      className={`fa  ${person.person.is_lead ? "fa-eye brivity-blue" : "fa-eye-slash"}`}
                      data-display-in-leads={person.person.is_lead !== undefined}
                    />
                  </div>
                </td>
              )}

              <td className="intent" aria-label="intent">
                <Intent person={person} />
              </td>

              <td className="tw-min-w-[150px] tw-w-[150px] status-header" aria-label="status">
                <Status person={person} />
              </td>

              <td className="tw-min-w-[150px] tw-w-[150px]" aria-label="stage">
                <Stage person={person} />
              </td>

              <td className="interaction-icon" aria-label="email">
                <EmailInteraction
                  personDetails={person.person_smsbutton_serializable_hash}
                  emailTemplates={person.email_templates}
                  currentUserId={currentUser.id}
                  person={person}
                  emailDetails={person.email_details}
                  unsubscribedMessageTypes={person.unsubscribed_message_types}
                />
              </td>

              <td className="interaction-icon" aria-label="call">
                <Tooltip
                  placement="right"
                  content={person.primary_phone_number}
                  trigger={
                    <IconButton
                      onClick={() => setCallLeadUuid(person.lead_person.uuid)}
                      disabled={!person.primary_phone_number}
                    >
                      <PhoneSolidV6 />
                    </IconButton>
                  }
                />
              </td>

              <td className="interaction-icon" aria-label="send sms">
                <SmsModalButton
                  gabbiAi={featureFlags.gabbiAi}
                  messagingServiceUrl={messagingServiceUrl}
                  smsEnabled={
                    person.primary_phone_number > "" && currentUser.managedPhoneNumber !== undefined
                  }
                  person={person.person_smsbutton_serializable_hash}
                  phone={primaryPhone}
                />
              </td>

              <td className="interaction-icon" aria-label="schedule appointment">
                <Tooltip
                  placement="right"
                  content="Schedule Appointment"
                  trigger={
                    <TaskFormLogAppointment
                      person={person.person}
                      current_user={currentUser}
                      account={account}
                      interaction={person.appointment_interaction}
                      googleCalendarClientId={googleCalendarClientId}
                      googleCalendarApiKey={googleCalendarApiKey}
                    />
                  }
                />
              </td>

              <td className="interaction-icon" aria-label="other">
                <OtherInteract
                  otherInteract={{
                    person: person.person,
                    personAvatar: person.personAvatar,
                    personFirstName: person.person.first_name,
                    personLastName: person.person.last_name,
                    interaction: person.other_interaction,
                  }}
                />
              </td>

              <td className="address" style={widths} aria-label="address">
                <Address
                  streetAddress={person.display_a_street_address}
                  cityState={person.display_city_state_and_zip}
                />
              </td>

              <td className="last-interaction text-center" aria-label="last interaction">
                <LastInteractions
                  interactionTypeString={person.interaction_type_string}
                  mostRecentInteractionTime={person.most_recent_interaction_time}
                />
              </td>

              <td className="next-due-tasks" aria-label="next due task">
                <NewTask
                  account_uuid={account.uuid}
                  accountMilestoneNames={accountMilestoneNames}
                  user={currentUser}
                  button_style="link"
                  task_for={person.task_for}
                  next_task={person.next_task}
                  taskable={person.taskable}
                />
              </td>

              <td className="primary_agent" aria-label="primary agent">
                <AgentOrPondSelect
                  small
                  max="full"
                  showSubTitle={false}
                  nameClasses="tw-max-w-[180px] tw-text-[14px]"
                  enableLeadPonds={enableLeadPond}
                  leadPond={person.leadPond}
                  personId={person.person?.id}
                  primaryAgent={person.primaryAgent}
                />
              </td>

              {autoPlansFeature && (
                <td className="action-plans text-center">
                  <AssignedPlansPreviewApp
                    assignedPlans={{
                      assignableId: person.person.uuid,
                      assignableType: "Person",
                      assignableName: person.full_name,
                      assignedPlansPath: person.assigned_plans_path,
                      autoPlansPath: person.auto_plans_path,
                      assignedPlansState: person.assigned_plans_state,
                      platformAccount: platformAccounts,
                    }}
                  />
                  {person.assigned_plans_activity_date && (
                    <h6 className="lower-row tw-pr-[10px]">{person.assigned_plans_activity_date}</h6>
                  )}
                </td>
              )}

              <td className="med-width-col" aria-label="birthdate">
                <MilestoneDate short milestoneDate={person.birthdate_milestone_date} />
              </td>

              <td className="med-width-col" aria-label="anniversary">
                <MilestoneDate milestoneDate={person.anniversary_milestone_date} />
              </td>

              <td className="home-app-link text-center" aria-label="home app link">
                <HomeAppLink
                  inviteSent={person.person.home_app_invite_sent}
                  lastLogin={person.person.home_app_last_login}
                  slug={person.person.slug}
                  personId={person.id}
                  emailActive={person.email_active}
                  smsActive={person.sms_active}
                  recipientFirstName={person.person.first_name}
                  recipientEmailAddress={person.person.main_email}
                  teamName={account.teamName}
                  currentUserFullName={currentUser.fullName}
                />
              </td>

              {hotLeadsFeature && (
                <td className="stat">
                  <h6 className="bolder">{person.person.hot_lead_score}</h6>
                </td>
              )}

              <td className="stat last-visit-col">
                <h6 className="last-visit">{lastVisit(person)}</h6>
              </td>

              <td className="stat loader">
                <h6 className="visits ">{Visits(person)}</h6>
                <h6 className="lower-row">
                  <span>Visits</span>
                </h6>
              </td>

              <td
                className={`stat loader ${hasIdxInfo(person.person) && "clickable"}`}
                aria-label="idx views"
              >
                <FavoritesOrViews
                  count={person.person.idx_property_views}
                  isFavorite={false}
                  person={person.person}
                  idxDataUri={idxDataUri}
                  idxDataToken={idxDataToken}
                />
              </td>

              <td className={`barely-wider-stat loader ${hasIdxInfo(person.person) && "clickable"}`}>
                <h6 className="price">
                  {person.idx_avg_price && person.idx_avg_price !== 0
                    ? person.number_to_currency_idx_avg_price
                    : "N/A"}
                </h6>
              </td>

              <td
                className={`stat loader ${hasIdxInfo(person.person) && "clickable"}`}
                aria-label="idx favorites"
              >
                <FavoritesOrViews
                  count={person.person.idx_favorites}
                  person={person.person}
                  idxDataUri={idxDataUri}
                  idxDataToken={idxDataToken}
                  isFavorite
                />
              </td>

              <td className={`stat loader ${hasIdxInfo(person.person) && "clickable"}`}>
                <h6 className="avg-favorites">
                  {person.idx_average_price_favorited && person.idx_average_price_favorited !== 0
                    ? person.number_to_currency_idx_average_price_favorited
                    : "N/A"}
                </h6>
              </td>

              <td className="stat loader alerts" aria-label="listing alerts">
                <AllListingAlertPreview
                  person={person.person}
                  emailDetails={person.primary_email_address}
                  geoCodeAccountAddress={account?.address?.geocodeString}
                />
              </td>

              <td className="stat med-width-col">
                <h6>{person.last_sent_listing_alert}</h6>
              </td>

              <td className="stat med-width-col">
                <h6>{person.last_opened_listing_alert}</h6>
              </td>

              <td className="stat market-reports med-width-col" aria-label="market reports">
                <MarketReportPreview
                  person={person.person_object}
                  person_status={person.person_status}
                  person_address={person.display_a_street_address}
                  person_lead_type={person.person_lead_type}
                  person_avatar={person.person_avatar}
                  editReportFn={editMarketReport}
                  deleteReportFn={(id) => deleteMarketReport(id, person?.primaryAgent?.uuid)}
                  openMarketReport={() => setMarketModal(true)}
                />
              </td>

              <td className="stat med-width-col">
                <h6>{person.last_opened_market_report}</h6>
              </td>

              <td className="stat med-width-col">
                <h6>{person.last_viewed_market_report}</h6>
              </td>

              <td className="source">
                <h6>{person.source}</h6>
                <h6>{person.referrer}</h6>
              </td>

              <td className="submitted">
                <h6>{person.submitted_date}</h6>
                <h6>{person.submitted_time}</h6>
              </td>

              {(from === "collaborators" || from === "teams") && (
                <td className="tw-min-w-[140px] tw-w-[140px]">
                  <h6 className="tw-ml-[30px] tw-justify-center	tw-flex">{person.transaction_role}</h6>
                </td>
              )}
              {from === "teams" && (
                <td className="tw-min-w-[140px] tw-w-[140px]">
                  <h6 className="tw-ml-[30px] tw-justify-center	tw-flex">{person.person.user_type}</h6>
                </td>
              )}
              <td className="icon" aria-label="person">
                <TagTooltip person={person} />
              </td>

              <td className="icon" aria-label="cma link">
                <CMALink person={person} setShowModal={() => setMarketModal(true)} />
              </td>
            </tr>
            {showAddModal && (
              <AddMarketReportModal
                person={{
                  ...person,
                  emails: person.email_details.map((e) => ({ category: e.category, address: e.value })),
                  addresses: [person?.display_a_street_address],
                }}
                primaryAgent={person?.primaryAgent?.uuid}
                isOpen
                close={() => setMarketModal(false)}
                id={editReportId}
              />
            )}
          </React.Fragment>
        );
      })}
    </tbody>
  );
};

LeadRows.propTypes = {
  setCallLeadUuid: Proptypes.func.isRequired,
  messagingServiceUrl: Proptypes.string.isRequired,
  people: Proptypes.arrayOf(
    Proptypes.shape({
      fullName: Proptypes.string,
    }),
  ),
  currentUser: Proptypes.shape({
    id: Proptypes.number,
    managedPhoneNumber: Proptypes.string,
    routingNumber: Proptypes.shape({ value: Proptypes.string }),
    fullName: Proptypes.string.isRequired,
  }),
  account: Proptypes.shape({
    uuid: Proptypes.string,
    address: Proptypes.shape({ geocodeString: Proptypes.string }),
    teamName: Proptypes.string.isRequired,
    fullName: Proptypes.string,
  }),
  googleCalendarClientId: Proptypes.string,
  googleCalendarApiKey: Proptypes.string,
  accountMilestoneNames: Proptypes.shape({
    Listing: Proptypes.arrayOf(Proptypes.string),
    Person: Proptypes.arrayOf(Proptypes.string),
    Project: Proptypes.arrayOf(Proptypes.string),
  }),
  platformAccounts: Proptypes.bool,
  autoPlansFeature: Proptypes.bool,
  hotLeadsFeature: Proptypes.bool,
  enableLeadPond: Proptypes.bool,
  idxDataUri: Proptypes.string,
  idxDataToken: Proptypes.string,
  from: Proptypes.string,
  featureFlags: Proptypes.shape().isRequired,
};
LeadRows.defaultProps = {
  people: [],
  currentUser: null,
  account: null,
  googleCalendarClientId: null,
  googleCalendarApiKey: null,
  accountMilestoneNames: null,
  platformAccounts: false,
  autoPlansFeature: false,
  hotLeadsFeature: false,
  enableLeadPond: false,
  idxDataUri: null,
  idxDataToken: null,
  from: null,
};
export default connect(mapStateToProps, { setCallLeadUuid: setLeadUuid })(LeadRows);
