import React from "react";
import Proptypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import RadioButton from "@shared/v2/RadioButton";
import { Dropdown } from "../shared/v1";
import formCss from "../PersonDetail/components/form.module.css";
import modalCss from "../PersonDetail/components/modal.module.css";

const RoleForTransaction = ({
  role: { label, value, agents },
  selectedAgentId,
  primary,
  setPrimaryAgent,
  visible,
  handleAgentSelect,
  handleVisibilitySelect,
  handleRemoveRole,
  showVisibility,
}) => {
  // TODO: add key prop in ListingTransactionModal when mapping
  // TODO: extract transaction modals and this file into a separate folder
  return (
    <Row>
      <Col xs={6} className="form-group">
        <label className={formCss.formLabel2}>
          {label.toUpperCase()}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content, jsx-a11y/control-has-associated-label */}
          <a
            role="button"
            tabIndex="0"
            onKeyDown={() => handleRemoveRole(value)}
            onClick={() => handleRemoveRole(value)}
            className="fa fa-times"
            style={{ fontSize: "9px", color: "#979797", cursor: "pointer", marginLeft: "8px" }}
          />
        </label>
        <Dropdown
          isSearchable
          isClearable={false}
          options={agents}
          value={selectedAgentId}
          onChange={(agent) => handleAgentSelect(agent.value, value)}
          variant="flatBlueishGray"
        />
      </Col>
      <Col xs={4} className={`form-group ${showVisibility ? "" : "invisible"}`}>
        <label htmlFor="Visibility" className={formCss.formLabel2}>
          VISIBILITY
        </label>
        <Dropdown
          isSearchable={false}
          isClearable={false}
          options={[
            { label: "Hidden", value: "false" },
            { label: "Visible", value: "true" },
          ]}
          value={visible}
          onChange={(agent) => handleVisibilitySelect(agent.value, value)}
          variant="flatBlueishGray"
        />
      </Col>
      <Col xs={2} className={`form-group ${modalCss.centerItems}`}>
        <label
          htmlFor="showVisibility"
          className={`${formCss.formLabel2} ${showVisibility ? "" : formCss.required}`}
        >
          PRIMARY
        </label>
        <RadioButton
          checked={primary === "true"}
          value={value}
          onChange={(e) => setPrimaryAgent(e.target.value)}
        />
      </Col>
    </Row>
  );
};
const optionShape = Proptypes.shape({
  label: Proptypes.string,
  value: Proptypes.oneOfType([Proptypes.string, Proptypes.number]),
});
const optionShapeRole = Proptypes.shape({
  label: Proptypes.string,
  value: Proptypes.oneOfType([Proptypes.string, Proptypes.number]),
  agents: Proptypes.arrayOf(optionShape),
});

RoleForTransaction.propTypes = {
  role: optionShapeRole,
  selectedAgentId: Proptypes.oneOfType([Proptypes.string, Proptypes.number]),
  primary: Proptypes.string,
  showVisibility: Proptypes.string,
  setPrimaryAgent: Proptypes.func,
  handleAgentSelect: Proptypes.func,
  handleVisibilitySelect: Proptypes.func,
  handleRemoveRole: Proptypes.func,
  visible: optionShape,
};
RoleForTransaction.defaultProps = {
  selectedAgentId: null,
  primary: "",
  setPrimaryAgent: null,
  visible: null,
  handleAgentSelect: null,
  handleVisibilitySelect: null,
  handleRemoveRole: null,
  showVisibility: "",
  role: {
    label: null,
    value: null,
    agents: [],
  },
};
export default RoleForTransaction;
