/* eslint-disable react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";

import { STYLES } from "../shared";

const PipedElements = ({ children, containerClassName }) => (
  <div className={`tw-flex tw-gap-8px ${containerClassName}`}>
    {children.map((element, index) => (
      <React.Fragment key={index}>
        {element}
        {index < children.length - 1 && <div className={STYLES.pipe} />}
      </React.Fragment>
    ))}
  </div>
);

export default PipedElements;

PipedElements.propTypes = {
  children: PropTypes.arrayOf(PropTypes.elementType).isRequired,
  containerClassName: PropTypes.string,
};

PipedElements.defaultProps = {
  containerClassName: "",
};
