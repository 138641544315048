import * as actionCreators from "../AddMarketReportModal/actions/creators";

const defaultModel = {
  street_address: null, // "Bellingham, WA, USA",
  city: null, // "Bellingham",
  county: null, // "Whatcom County",
  district: null,
  postal_code: null,
  state_abbreviation: "", // "WA",
  geo_json: null,
  boundry_coordinates: null,
  lat_lng: [],

  min_price: "0",
  max_price: null,
  min_area: null,
  max_area: null,
  min_baths: null,
  max_baths: null,
  min_beds: null,
  max_beds: null,
  min_lot: null,
  max_lot: null,
  min_year_built: null,
  max_year_built: null,

  name: "",

  radius: "0.5",
  search_type: "radius",
  property_type: {},
  mls_country: "USA",
  created_by_brivity_agent_uuid: null,
  searchLocation: null,

  contact: {
    email: "",
    address: "",
    city: "",
    state: "",
    zip_code: "",
  },
};

const defaultModel2 = {
  email_message:
    "Check out this Market Report I created for you that shows what's going on in the real estate market in your area.",
  drip_enabled: true,
  name: "",
  recurring_frequency: "every_2_weeks",
  home_value_adjustment: null,
  home_value_adjustment_as_percentage: false,
  report_type: 'home_value',
  isHomeAdjPositive: true,
};

const defaultState = {
  tab: null,
  isSearching: false,
  isSaving: false,
  isLoading: false,
  isSending: false,
  form1: { ...defaultModel },
  isDrawing: false,
  browserLatLon: null,
  formErrors: {
    property_type: false,
    contact_email: true,
    lat_lng: false,
  },
  form2: { ...defaultModel2 },
  savedMarketReport: null,
  showSuccess: false,
  showError: false,
  person: null,
  mlsToPropertyTypes: null,
};

const newMarketReport = (state = defaultState, payload) => {
  switch (payload.type) {
    case actionCreators.setTab().type:
      return {
        ...state,
        tab: payload.data,
      };
    case actionCreators.setDrawing().type:
      return {
        ...state,
        isDrawing: payload.data,
        form1: { ...state.form1, geo_json: payload.data ? state.form1.geo_json : null },
      };
    case actionCreators.setLatLon().type:
      return {
        ...state,
        form1: { ...state.form1, lat_lng: payload.data },
      };
    case actionCreators.setBrowserLatLon().type:
      return {
        ...state,
        browserLatLon: payload.data,
      };
    case actionCreators.setLocationData().type:
      return {
        ...state,
        locationData: payload.data,
        isSearching: false,
      };
    case actionCreators.setFormData().type:
      return {
        ...state,
        form1: { ...state.form1, ...payload.data },
        hasSavingError: false,
      };
    case actionCreators.setContactData().type:
      return {
        ...state,
        form1: { ...state.form1, contact: { ...state.form1.contact, ...payload.data } },
      };
    case actionCreators.setForm2Data().type:
      return {
        ...state,
        form2: { ...state.form2, ...payload.data },
      };
    case actionCreators.setFormError().type:
      return {
        ...state,
        formErrors: { ...state.formErrors, ...payload.data },
      };
    case actionCreators.saveMarketReport().type:
      return {
        ...state,
        showError: false,
        isSaving: true,
      };
    case actionCreators.saveMarketReportSuccess().type:
      return {
        ...state,
        isSaving: false,
        tab: 1,
        savedMarketReport: payload.data,
        form2: { ...state.form2, name: payload.data.name },
      };
    case actionCreators.saveMarketReportError().type:
      return {
        ...state,
        isSaving: false,
        showError: true,
        tab: 0, // comment out to disable advancing to the next panel
      };
    case actionCreators.sendMarketReport().type:
      return {
        ...state,
        showError: false,
        isSending: true,
      };
    case actionCreators.sendMarketReportSuccess().type:
      return {
        ...state,
        isSending: false,
        showError: false,
        showSuccess: true,
      };
    case actionCreators.sendMarketReportError().type:
      return {
        ...state,
        isSending: false,
        showError: true,
      };
    case actionCreators.updateMarketReport().type:
      return {
        ...state,
        showError: false,
        isSaving: true,
      };
    case actionCreators.updateMarketReportSuccess().type:
      return {
        ...state,
        isSaving: false,
        tab: 1,
        savedMarketReport: payload.data,
        form2: { ...state.form2, name: payload.data.name },
      };
    case actionCreators.updateMarketReportError().type:
      return {
        ...state,
        isSaving: false,
        showError: true,
        tab: 0, // comment out to disable advancing to the next panel
      };

    case actionCreators.getMarketReport().type:
      return {
        ...state,
        showError: false,
        isLoading: true,
      };
    case actionCreators.getMarketReportSuccess().type:
      const pTypes = {};
      payload.data?.property_type?.split("|").forEach((t) => {
        pTypes[t] = true;
      });
      const location =
        payload.data.location && payload.data.location !== " , , " ? payload.data.location : null;
      const geoJ = payload.data.geo_json;
      delete payload.data.geo_json;
      delete payload.data.location;
      delete payload.data.created_by_brivity_agent_uuid;
      delete payload.data.created_by;
      return {
        ...state,
        isLoading: false,
        showError: false,
        // this is key
        tab: payload.tab,
        savedMarketReport: payload.data,
        form1: {
          ...state.form1,
          ...payload.data,
          street_address: payload.data.street_address,
          property_type: pTypes,
          min_beds: payload.data.min_beds === null ? null : payload.data.min_beds,
          min_baths:
            payload.data.min_baths === null || payload.data.min_baths === "0.0"
              ? null
              : payload.data.min_baths,
          min_price: payload.data.min_price === null ? "0" : payload.data.min_price?.toString(),
          max_price: payload.data.max_price?.toString(),
          boundry_coordinates: geoJ ? JSON.parse(geoJ) : null,
          search_type: geoJ ? "area" : "radius",
          searchLocation: location && {
            label: location,
            value: location,
            source: payload.data.search_type === "radius" ? "GoogleAPI" : "LocationAPI",
          },
        },
        form2: {
          ...state.form2,
          name: payload.data.name,
          recurring_frequency: payload.data.recurring_frequency,
        },
      };
    case actionCreators.getMarketReportError().type:
      return {
        ...state,
        isLoading: false,
        showError: true,
      };

    case actionCreators.clearMessage().type:
      return {
        ...state,
        showSuccess: false,
        showError: false,
      };
    case actionCreators.setSearching().type:
      return {
        ...state,
        isSearching: payload.data,
      };
    case actionCreators.setMessage().type:
      return {
        ...state,
        showSuccess: payload.data.showSuccess ? payload.data.showSuccess : false,
        showError: payload.data.showError ? payload.data.showError : false,
      };
    case actionCreators.resetForms().type:
      return {
        ...state,
        form1: { ...defaultModel },
        form2: { ...defaultModel2 },
        showSuccess: false,
        showError: false,
        tab: null,
        isDrawing: false,
        person: null,
        savedMarketReport: null,
      };
    case actionCreators.setPerson().type:
      return {
        ...state,
        person: payload.data,
      };
    case actionCreators.setMLSPropertyTypes().type:
      const allPropertyTypes = new Set();
      payload.data?.forEach(e => {
        if (!e.propertyTypes?.length) return;
        e.propertyTypes.forEach(p => allPropertyTypes.add(p));
      });
      return {
        ...state,
        mlsToPropertyTypes: Array.from(allPropertyTypes),
      };
    default:
      return state;
  }
};

export default newMarketReport;
