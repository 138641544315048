import React from "react";
import Linkify from "linkify-react";

import { AtSolidV6 } from "@shared/v2/Icomoon";

import ActivitiesContainer from "./ActivitiesContainer";
import { activityCommonDefaultProps, activityCommonPropTypes } from "../shared";

const mentionMap = new Map();

const calculateNoteContent = (content) => {
  content.replace(/\[([^\]]+)\]\(([^)]+)\)/g, (_, name, id) => {
    mentionMap.set(id, name);
    return `${window.location.host}/people/${id}`;
  });
};

const NoteMentionActivity = ({ activity, onReadChange }) => {
  const { note } = activity.data;
  const noteContent = calculateNoteContent(note);

  return (
    <ActivitiesContainer
      activity={activity}
      onReadChange={onReadChange}
      Icon={AtSolidV6}
      title={activity.title}
    >
      Mention:
      <Linkify
        className="tw-whitespace-pre-wrap tw-break-words"
        tagName="span"
        options={{
          defaultProtocol: "https",
          target: "_blank",
          render: {
            url: ({ attributes, content }) => {
              const mentionId = content.split("/").pop();
              return (
                <a className="tw-text-theme-primary" {...attributes}>
                  {mentionMap.has(mentionId) ? `@${mentionMap.get(mentionId)}` : content}
                </a>
              );
            },
          },
        }}
      >
        {noteContent}
      </Linkify>
    </ActivitiesContainer>
  );
};

export default NoteMentionActivity;

NoteMentionActivity.propTypes = {
  ...activityCommonPropTypes,
};

NoteMentionActivity.defaultProps = {
  ...activityCommonDefaultProps,
};
