import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { GearSolidV6, WandMagicSparklesSolidV6 } from "@shared/v2/Icomoon";
import Dropdown from "@shared/v2/Dropdown";
import IconButton from "@shared/v2/IconButton";
import TextButton from "@shared/v2/TextButton";
import HeadingSelect from "./HeadingSelect";
import Section from "./Section";
import TeamBusinessGoals from "./TeamBusinessGoals";
import DefaultableGoals from "./DefaultableGoals";
import { Menu, Option, SingleValue } from "./CustomDropdownComponents";
import TeamActivityGoals from "./TeamActivityGoals";
import TeamDefaultsProductionModal from "./TeamDefaultsProductionModal";
import TeamProductionWizardModal from "./TeamProductionWizardModal";
import { useCurrentUser } from "../../reducers/layoutReducer/selectors";

const ReportingGoals = ({ agents }) => {
  const { isPlace, isAdmin } = useCurrentUser().account;
  const params = new URLSearchParams(window.location.search);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [teamProductionIsOpen, setTeamProductionIsOpen] = useState(false);
  const [wizardIsOpen, setWizardIsOpen] = useState(false);
  const [year, setYear] = useState(params.get("year") || new Date().getFullYear());
  const dropdownOptions = useMemo(() =>
    agents.map(({ id, full_name: fullName, avatar }) => ({
      value: id,
      label: fullName,
      meta: { avatar },
    })),
  );
  const [agent, setAgent] = useState(dropdownOptions?.find(({ value }) => value === null));

  useEffect(() => {
    const searchParams = new URLSearchParams({ year });
    window.history.pushState(null, null, `${window.location.pathname}?${searchParams}`);
  }, [year]);

  return (
    <div className="tw-bg-neutral-gray-5 tw-p-[16px]">
      <div className="tw-mx-auto tw-max-w-[1320px] tw-w-full tw-flex tw-flex-col tw-gap-[24px]">
        <Section className="tw-flex tw-items-baseline tw-justify-between">
          <div className="tw-w-full tw-flex tw-justify-between tw-align-top tw-flex-wrap">
            <div>
              <div className="tw-flex tw-items-baseline">
                <HeadingSelect
                  name="year"
                  title={<h2 className="tw-text-24d tw-m-0 tw-mr-[16px]">Goal Setting</h2>}
                  value={year}
                  onChange={setYear}
                />
                {isPlace && isAdmin && (
                  <>
                    <IconButton size="small" schema="tertiary" onClick={() => setTeamProductionIsOpen(true)}>
                      <GearSolidV6 />
                    </IconButton>
                    <TeamDefaultsProductionModal
                      show={teamProductionIsOpen}
                      year={year}
                      onClose={() => setTeamProductionIsOpen(false)}
                    />
                  </>
                )}
                {isPlace && (
                  <TeamProductionWizardModal
                    agent={agent}
                    show={wizardIsOpen}
                    year={year}
                    onClose={() => setWizardIsOpen(false)}
                  />
                )}
              </div>
              <p className="tw-text-14d tw-text-gray-50 tw-mt-4">
                Set business, production, and activity tracking goals for a period of time. View progress
                towards goals on your dashboards.
              </p>
            </div>
            {isAdmin && (
              <div className="tw-flex tw-flex-col">
                <Dropdown
                  menuIsOpen={menuIsOpen}
                  onMenuOpen={() => setMenuIsOpen(true)}
                  onMenuClose={() => setMenuIsOpen(false)}
                  components={{ Menu, Option, SingleValue }}
                  className="tw-min-w-[300px]"
                  label="Assign goals for:"
                  value={agent}
                  options={dropdownOptions}
                  onChange={setAgent}
                />
              </div>
            )}
          </div>
        </Section>

        <div className="tw-flex tw-flex-wrap tw-items-start tw-gap-[16px]">
          <div className="tw-basis-[500px] tw-grow tw-flex tw-flex-col tw-gap-[16px]">
            <TeamBusinessGoals year={year} isAdmin={isAdmin} />
            <DefaultableGoals
              action={
                isPlace ? (
                  <TextButton
                    onClick={() => setWizardIsOpen(true)}
                    className="tw-flex tw-items-center tw-gap-[4px] tw-ml-[-6px] tw-mt-[16px]"
                  >
                    <WandMagicSparklesSolidV6 /> Help Me Set My Goals
                  </TextButton>
                ) : null
              }
              agent={agent}
              refresh={!wizardIsOpen}
              year={year}
              isAdmin={isAdmin}
              section="agent_production"
              title="Personal Production Goals"
              description={
                agent?.value ? (
                  <div>
                    Set the <span className="tw-font-bold">production standards for {agent.label}</span> on
                    goals such as units sold, units listed, units pended, closed volume, and gross commision
                    income (GCI). Changes made here will not overwrite existing user-specific customization.
                  </div>
                ) : (
                  "Set individual production goals for yourself based on units sold, units listed, units pended, closed volume, gross commission income (GCI). "
                )
              }
            />
          </div>
          <div className="tw-basis-[500px] tw-grow tw-flex tw-flex-col tw-gap-[16px]">
            <TeamActivityGoals year={year} isAdmin={isAdmin} />
            <DefaultableGoals
              agent={agent}
              year={year}
              isAdmin={isAdmin}
              section="lead_interaction"
              title="Brivity Activity Goals"
              description={
                agent?.value ? (
                  <div>
                    Set <span className="tw-font-bold">{agent.label}’s goals</span> for activities that are
                    tracked within Brivity. Note that these are automatically logged and do not require manual
                    entry.
                  </div>
                ) : (
                  "Set goals for activities tracked within Brivity. Note that these are automatically logged and do not require manual entry."
                )
              }
            />
            <DefaultableGoals
              agent={agent}
              year={year}
              isAdmin={isAdmin}
              section="activity"
              title="Logged Activity Goals"
              description={
                agent?.value ? (
                  <div>
                    Set the <span className="tw-font-bold">activity goal standards for {agent.label}</span>{" "}
                    for manually logged lead generation activities, lead nurturing activities, or custom goals
                    that you want your team to track. Changes made here will not overwrite existing
                    user-specific customization.
                  </div>
                ) : (
                  "Track progress in activities related to lead generation, lead nurturing, or any other custom goals you wish to track."
                )
              }
              customGoals
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ReportingGoals.propTypes = {
  agents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ReportingGoals;
