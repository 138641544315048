export const ADDRESS_PARTS = [
  {
    label: "Street Address",
    key: "streetAddress",
  },
  {
    label: "City",
    key: "city",
  },
  {
    label: "State / Province",
    key: "locality",
  },
  {
    label: "Zip / Postal Code",
    key: "postalCode",
  },
];

export const GENERAL_DETAILS = [
  { label: "First Name", key: "firstName" },
  { label: "Last Name", key: "lastName" },
  { label: "Person Type", key: "typeName" },
  { label: "Source", key: "source" },
  { label: "Status", key: "status" },
  { label: "Intent", key: "leadType" },
];
