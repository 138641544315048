import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import format from "@shared/formatters";
import Alert from "@shared/v2/Alert";
import Button from "@shared/v2/Button";
import Modal from "@shared/v2/Modal";
import TextButton from "@shared/v2/TextButton";
import Typography from "@shared/v2/Typography";
import FlatPercent from "@shared/v2/InlineEditingInput/FlatPercent";
import Tooltip from "@shared/v2/Tooltip";
import { BullseyeArrowRegularV6, ChevronDownSolidV6, CircleInfoSolidV6 } from "@shared/v2/Icomoon";

const LabeledFlat = ({ label, type = "percent", ...props }) => (
  <div className="tw-flex tw-justify-between tw-items-center tw-gap-[8px] tw-min-h-[36px]">
    <Typography variant="subtitle-s" className="!tw-text-gray-50">
      {label}
    </Typography>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <FlatPercent showToggle={false} placeholder={" "} flatOrPercent={type} {...props} />
  </div>
);

LabeledFlat.propTypes = {
  label: PropTypes.node.isRequired,
  type: PropTypes.oneOf(["flat", "percent"]),
};

LabeledFlat.defaultProps = {
  type: "percent",
};

const RecommendedGoals = ({ onClose, show, year, wizardData }) => {
  const { actions, errors, goals: initialGoals } = wizardData;
  const [showIncomeGoal, setShowIncomGoal] = useState(true);
  const [showMore, setShowMore] = useState(false);
  const [baseGoals, setBaseGoals] = useState({ ...initialGoals });

  useEffect(() => {
    if (show) setBaseGoals({ ...initialGoals });
  }, [show, initialGoals]);

  const onChange = (name) => (value) => {
    const cleanValue = typeof value === "string" ? value.replace(/[^0-9.]/g, "") : value;
    if (cleanValue > 0) {
      setBaseGoals((prev) => ({
        ...prev,
        [name]: cleanValue,
      }));
    }
  };

  const resetDisabled = useMemo(
    () => !Object.entries(baseGoals).some(([key, value]) => initialGoals[key] !== value),
    [baseGoals],
  );

  const goals = useMemo(() => {
    const {
      avgCommission,
      avgSalePrice,
      brokerageCap,
      brokerageSplitPercentage,
      buyerCommissionSplit,
      currentNetIncomeGoal,
      pendingToCloseRatio,
      royaltyCap,
      royaltyPercentage,
      sellerCommissionSplit,
      sellerToTotalClosingsRatio,
    } = baseGoals;
    const brokerageSplitPercent = brokerageSplitPercentage / 100;
    const royaltySplitPercent = royaltyPercentage / 100;
    let maxBrokerageCap = currentNetIncomeGoal * brokerageSplitPercent;
    let maxRoyaltyCap = currentNetIncomeGoal * royaltySplitPercent;
    if (maxBrokerageCap > brokerageCap) maxBrokerageCap = brokerageCap;
    if (maxRoyaltyCap > royaltyCap) maxRoyaltyCap = royaltyCap;
    const averageSalePrice = String(avgSalePrice).replace(",", "");

    const pendingToCloseRatioPercent = pendingToCloseRatio / 100;
    const sellerToTotalClosingRatioPercent = 1 - sellerToTotalClosingsRatio / 100;

    const averageCommission = averageSalePrice * (avgCommission / 100);
    const sellerCommission =
      averageCommission * (sellerCommissionSplit / 100) * sellerToTotalClosingRatioPercent;
    const buyerCommission =
      averageCommission * (buyerCommissionSplit / 100) * (1 - sellerToTotalClosingRatioPercent);

    const closedUnits = Math.ceil(
      (Number(currentNetIncomeGoal) + Number(maxBrokerageCap) + Number(maxRoyaltyCap)) /
        (sellerCommission + buyerCommission),
    );
    const pendedUnits = Math.ceil(closedUnits / pendingToCloseRatioPercent);
    const listedUnits = Math.ceil(pendedUnits * (1 - sellerToTotalClosingRatioPercent));
    const closedVolume = closedUnits * averageSalePrice;

    return { closedVolume, closedUnits, pendedUnits, listedUnits };
  }, [baseGoals]);

  return (
    <>
      <Modal.Header closeButton title={`Your ${year} Recommended Goals`} />
      <Modal.Body className="tw-flex-1 tw-overflow-auto tw-relative tw-flex tw-flex-col tw-gap-[24px]">
        {errors.global && <Alert variant="error" text={errors.global} />}
        <div className="tw-flex tw-gap-[16px]">
          <div className="tw-flex-1 tw-basis-[33%] tw-p-[16px] tw-flex tw-gap-[16px] tw-items-center tw-border-[1px] tw-border-solid tw-border-neutral-gray-15 tw-rounded-[4px]">
            <BullseyeArrowRegularV6 size="xxl" />
            <div className="tw-flex tw-flex-col">
              <Typography variant="subtitle-m">Net Income Goal</Typography>
              <div className="tw-flex tw-items-center tw-gap-[6px] tw-min-h-[36px]">
                {showIncomeGoal && (
                  <Typography component="span" variant="headline2" className="!tw-text-black">
                    {format.currency(baseGoals.currentNetIncomeGoal, 0)}
                  </Typography>
                )}{" "}
                <FlatPercent
                  flatOrPercent="flat"
                  placeholder=" "
                  showToggle={false}
                  onInteract={() => setShowIncomGoal((o) => !o)}
                  onCancelEditing={() => setShowIncomGoal((o) => !o)}
                  onConfirmEditing={() => setShowIncomGoal((o) => !o)}
                  displayComponent={(handleInteract) => (
                    <TextButton onClick={handleInteract}>Change</TextButton>
                  )}
                  onChange={onChange("currentNetIncomeGoal")}
                />
              </div>
            </div>
          </div>
          <div className="tw-flex-1 tw-basis-[33%] tw-flex tw-flex-col tw-gap-[12px]">
            <Typography>
              List{" "}
              <Typography component="span" variant="headline2" className="!tw-text-black">
                {goals.listedUnits}
              </Typography>{" "}
              units on market
            </Typography>
            <Typography>
              Pend{" "}
              <Typography component="span" variant="headline2" className="!tw-text-black">
                {goals.pendedUnits}
              </Typography>{" "}
              units under contract
            </Typography>
            <Typography>
              Close on at least{" "}
              <Typography component="span" variant="headline2" className="!tw-text-black">
                {goals.closedUnits}
              </Typography>{" "}
              of those units
            </Typography>
            <Typography>
              Close{" "}
              <Typography component="span" variant="headline2" className="!tw-text-black">
                {format.currency(goals.closedVolume, 0)}
              </Typography>{" "}
              of total volume
            </Typography>
          </div>
        </div>
        <div className="tw-flex tw-flex-col tw-gap-[16px]">
          <Typography variant="subtitle-s" className="!tw-font-normal">
            Goals are calculated using production data suggested by PLACE. <b>Click the stats</b> below to
            fine tune and maximize the accuracy of your goals.
          </Typography>
          <div className="tw-flex tw-gap-[24px]">
            <div className="tw-flex-1 tw-flex tw-flex-col">
              <LabeledFlat
                type="flat"
                label="Avg. Sale Price"
                value={format.number(baseGoals.avgSalePrice, 0)}
                onChange={onChange("avgSalePrice")}
              />
              <LabeledFlat
                label="Avg. Commission"
                value={baseGoals.avgCommission}
                onChange={onChange("avgCommission")}
              />
              <LabeledFlat
                label="Pending to Closed Ratio"
                value={baseGoals.pendingToCloseRatio}
                onChange={onChange("pendingToCloseRatio")}
              />
              <LabeledFlat
                label={
                  <span>
                    Seller to Total Closings Ratio{" "}
                    <Tooltip
                      trigger={<CircleInfoSolidV6 />}
                      content="The percentage of buyer and seller closings that were sellers"
                      innerClassName="tw-max-w-[230px]"
                    />
                  </span>
                }
                value={baseGoals.sellerToTotalClosingsRatio}
                onChange={onChange("sellerToTotalClosingsRatio")}
              />
              <TextButton className="tw-self-start tw-ml-[-6px]" onClick={() => setShowMore((more) => !more)}>
                {showMore ? "Hide" : "Additional"} Values{" "}
                <ChevronDownSolidV6
                  size="s"
                  className={`tw-transition-transform ${showMore ? "tw-rotate-180" : ""}`}
                />
              </TextButton>
              {showMore && (
                <>
                  <LabeledFlat
                    label="Seller Commission Split w/ Team"
                    value={baseGoals.sellerCommissionSplit}
                    onChange={onChange("sellerCommissionSplit")}
                  />
                  <LabeledFlat
                    label="Buyer Commission Split w/ Team"
                    value={baseGoals.buyerCommissionSplit}
                    onChange={onChange("buyerCommissionSplit")}
                  />
                </>
              )}
            </div>
            <div className="tw-flex-1 tw-flex tw-flex-col">
              <LabeledFlat
                label="Brokerage Split"
                value={baseGoals.brokerageSplitPercentage}
                onChange={onChange("brokerageSplitPercentage")}
              />
              <LabeledFlat
                type="flat"
                label="Brokerage Cap"
                value={format.number(baseGoals.brokerageCap, 0)}
                onChange={onChange("brokerageCap")}
              />
              <LabeledFlat
                label="Royalty Split"
                value={baseGoals.royaltyPercentage}
                onChange={onChange("royaltyPercentage")}
              />
              <LabeledFlat
                type="flat"
                label="Royalty Cap"
                value={format.number(baseGoals.royaltyCap, 0)}
                onChange={onChange("royaltyCap")}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="tw-flex tw-justify-between tw-gap-[8px]">
        <Button size="medium" schema="tertiary" onClick={onClose}>
          Cancel
        </Button>
        <div className="tw-flex tw-gap-[12px]">
          <TextButton onClick={() => setBaseGoals({ ...initialGoals })} disabled={resetDisabled}>
            Reset Values
          </TextButton>
          <Button size="medium" onClick={() => actions.saveGoals(goals).then(onClose)}>
            Save Goals
          </Button>
        </div>
      </Modal.Footer>
    </>
  );
};

RecommendedGoals.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  wizardData: PropTypes.shape().isRequired,
  year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default RecommendedGoals;
