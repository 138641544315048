import { useState, useEffect } from "react";
import axios from "axios";
import { trim } from "lodash";
import { format, isToday, isYesterday } from "date-fns";
import { useDebounce } from "@shared/hookHelpers";
import { responseDataToCamelCase } from "@shared/v2/caseTransformingAxios";
import { getActivities, markAllAsRead, markAsRead, searchActivities, getUnreadCount } from "../api";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  if (isToday(date)) return "Today";
  if (isYesterday(date)) return "Yesterday";
  return format(date, "MM/dd/yy");
};

const groupAndSortByDate = (data) => {
  if (data.length === 0) return [];
  const groupedData = data.reduce((acc, activity) => {
    if (!activity) return acc;
    const dateKey = new Date(activity.createdAt).toISOString().split("T")[0];

    if (!acc[dateKey]) {
      acc[dateKey] = [];
    }

    acc[dateKey].push(activity);

    return acc;
  }, {});

  Object.keys(groupedData).forEach((date) => {
    groupedData[date].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  });

  const sortedArray = Object.entries(groupedData)
    .sort((a, b) => new Date(b[0]) - new Date(a[0]))
    .map(([date, activities]) => ({ date: formatDate(date), activities }));

  return sortedArray;
};

const transformNotificationData = (notification) => ({
  ...notification,
  data:
    typeof notification.data === "string"
      ? responseDataToCamelCase(JSON.parse(notification.data || "{}"))
      : notification.data,
});

const useActivities = ({ query: initQuery, show }) => {
  const query = useDebounce(trim(initQuery), 300);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [unreadCount, setUnreadCount] = useState(0);
  const [searchResults, setSearchResults] = useState([]);
  const [data, setData] = useState([]);
  const [sortedActivities, setSortedActivities] = useState(groupAndSortByDate(data));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUnreadCount().then((count) => setUnreadCount(count));
  }, []);

  useEffect(() => {
    if (query) setSortedActivities(groupAndSortByDate(searchResults));
    else setSortedActivities(groupAndSortByDate(data));
  }, [data, query, searchResults]);

  useEffect(() => {
    if (page > 1) {
      setLoading(true);
      getActivities({ params: { page, count: 30 } })
        .then((d) => {
          setLoading(false);
          setData((prev) => [...prev, ...(d?.notifications || []).map(transformNotificationData)]);
        })
        .catch(() => setLoading(false));
    }
  }, [page]);

  useEffect(() => {
    if (!show) return () => {};
    const abortController = new AbortController();

    getActivities({ params: { page: 1, count: 30 }, signal: abortController.signal }).then((d) => {
      setPage(1);
      setMaxPage(d?.totalPages || 1);
      setData((d?.notifications || []).map(transformNotificationData));
    });

    return () => abortController.abort();
  }, [show]);

  useEffect(() => {
    if (!query) return () => {};
    const abortController = new AbortController();

    setLoading(true);
    searchActivities({ params: { query }, signal: abortController.signal })
      .then((d) => {
        setSearchResults((d || []).map(transformNotificationData));
        setLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) setLoading(false);
      });

    return () => {
      abortController.abort();
    };
  }, [query]);

  const actions = {
    onReadChange: (activity) => {
      setData((prev) =>
        prev.map((a) => {
          if (a.id === activity.id) {
            return { ...a, loading: true };
          }
          return a;
        }),
      );

      markAsRead(activity)
        .then(() => {
          setUnreadCount((c) => c + (activity.read ? 1 : -1));
          setSearchResults((prev) =>
            prev.map((a) => {
              if (a.id === activity.id) {
                return { ...a, read: !activity.read, loading: false };
              }
              return a;
            }),
          );
          setData((prev) =>
            prev.map((a) => {
              if (a.id === activity.id) {
                return { ...a, read: !activity.read, loading: false };
              }
              return a;
            }),
          );
        })
        .catch(console.log);
    },

    markAllAsRead: () => {
      setLoading(true);

      markAllAsRead()
        .then(() => {
          setLoading(false);
          setUnreadCount(0);
          setData((prev) => prev.map((a) => ({ ...a, read: true, loading: false })));
        })
        .catch(() => setLoading(false));
    },

    loadMore: () => {
      if (!query && page < maxPage) setPage((p) => p + 1);
    },
  };

  return { actions, activityGroups: sortedActivities, loading, unreadCount };
};

export default useActivities;
