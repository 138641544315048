import React, { useState, useEffect, useRef, forwardRef } from "react";

import HeaderCell from "../../Transactions/components/shared/HeaderCell";
import Checkbox from "@shared/v2/Checkbox";
import { MailboxSolidV6, PhoneArrowUpRightSolidV6, PhoneSolidV6 } from "@shared/v2/Icomoon";
import Dnc from "./Dnc";
import IconButton from "@shared/v2/IconButton";
import Tooltip from "@shared/v2/Tooltip";
import { prospectNameColor } from "./helpers";

const Table = ({
  config,
  properties,
  selected,
  onSelect,
  onSelectAll,
  availableHeight,
  highlightedProperty,
  openPropertyDetails,
  callPropertyProspects,
  currentSort,
  setCurrentSort,
}) => {
  const rowRefs = useRef({});
  const [sortedProperties, setSortedProperties] = useState([]);

  const padding = "tw-px-20px tw-pb-8px tw-pt-0";

  const sortOptions = ["asc", "desc"];
  const getSortOrder = (field) => (currentSort?.field === field ? currentSort.order : null);

  const onSort = (field, order) => {
    setCurrentSort({ field, order });
  };

  useEffect(() => {
    const sorted =
      properties?.toSorted((a, b) => {
        if (a[currentSort.field] > b[currentSort.field]) {
          return currentSort.order === "asc" ? 1 : -1;
        } else if (a[currentSort.field] < b[currentSort.field]) {
          return currentSort.order === "asc" ? -1 : 1;
        }

        return 0;
      }) || [];
    setSortedProperties(sorted);
  }, [properties, currentSort.field, currentSort.order]);

  useEffect(() => {
    if (rowRefs.current[highlightedProperty]) {
      rowRefs.current[highlightedProperty].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [highlightedProperty]);

  return (
    <div
      style={{ maxHeight: `${availableHeight}px` }}
      className="tw-w-auto tw-flex tw-flex-col tw-flex-nowrap tw-overflow-auto tw-grow tw-shrink-0 tw-mx-[-20px]"
    >
      <table className="tw-border-separate tw-overflow-scroll tw-table-auto tw-flex-nowrap tw-border-spacing-0">
        <thead>
          <tr className="tw-text-gray-50 tw-h-32px tw-bg-white tw-sticky tw-top-0 tw-z-20 tw-whitespace-nowrap">
            <th className="tw-p-0 tw-sticky tw-left-0 tw-z-30 tw-bg-white tw-top-0 tw-border-0 tw-border-b tw-border-solid tw-border-gray-light tw-pb-10px">
              <div className="tw-flex tw-w-full tw-items-center tw-ml-20px">
                <Checkbox checked={selected.length === properties.length} onChange={onSelectAll} />
              </div>
            </th>
            <HeaderCell
              title="Address"
              px={padding}
              className="tw-left-[34px] tw-z-30 tw-border-r"
              isSortable
              sortOptions={sortOptions}
              sortedBy={getSortOrder("streetAddress")}
              onSort={(order) => onSort("streetAddress", order)}
              fontSettings="tw-text-12px tw-font-semibold"
            />
            <HeaderCell
              title="Status"
              px={padding}
              isSortable
              sortOptions={sortOptions}
              sortedBy={getSortOrder("statusDate")}
              onSort={(order) => onSort("statusDate", order)}
              fontSettings="tw-text-12px tw-font-semibold"
            />
            <HeaderCell
              title="Recommended Prospect"
              px={padding}
              fontSettings="tw-text-12px tw-font-semibold"
            />
            <HeaderCell title="Call" px={padding} fontSettings="tw-text-12px tw-font-semibold" />
            {config.showAllPersonsColumn ? (
              <HeaderCell
                title="All Prospects"
                px={padding}
                fontSettings="tw-text-12px tw-font-semibold"
                justify="tw-justify-center"
              />
            ) : null}
            <HeaderCell
              title="Engagement"
              px={padding}
              fontSettings="tw-text-12px tw-font-semibold"
              justify="tw-justify-center"
            />
            <HeaderCell title="List Price" px={padding} fontSettings="tw-text-12px tw-font-semibold" />
            {config.showDaysOnMarketColumn ? (
              <HeaderCell
                title="Days on Market"
                px={padding}
                isSortable
                sortOptions={sortOptions}
                sortedBy={getSortOrder("daysOnMarket")}
                onSort={(order) => onSort("daysOnMarket", order)}
                fontSettings="tw-text-12px tw-font-semibold"
              />
            ) : null}
            <HeaderCell
              title="Beds"
              px={padding}
              isSortable
              sortOptions={sortOptions}
              sortedBy={getSortOrder("beds")}
              onSort={(order) => onSort("beds", order)}
              fontSettings="tw-text-12px tw-font-semibold"
            />
            <HeaderCell
              title="Baths"
              px={padding}
              isSortable
              sortOptions={sortOptions}
              sortedBy={getSortOrder("baths")}
              onSort={(order) => onSort("baths", order)}
              fontSettings="tw-text-12px tw-font-semibold"
            />
            <HeaderCell
              title="SQFT"
              px={padding}
              isSortable
              sortOptions={sortOptions}
              sortedBy={getSortOrder("sqft")}
              onSort={(order) => onSort("sqft", order)}
              fontSettings="tw-text-12px tw-font-semibold"
            />
            <HeaderCell
              title="Lot Size (Acres)"
              px={padding}
              isSortable
              sortOptions={sortOptions}
              sortedBy={getSortOrder("lotSize")}
              onSort={(order) => onSort("lotSize", order)}
              fontSettings="tw-text-12px tw-font-semibold"
            />
            <HeaderCell
              title="Year Built"
              px={padding}
              isSortable
              sortOptions={sortOptions}
              sortedBy={getSortOrder("yearBuilt")}
              onSort={(order) => onSort("yearBuilt", order)}
              fontSettings="tw-text-12px tw-font-semibold"
            />
            <HeaderCell title="Property Type" px={padding} fontSettings="tw-text-12px tw-font-semibold" />
          </tr>
        </thead>
        <tbody className="tw-text-gray-99">
          {sortedProperties.map((p) => (
            <RefRow
              ref={(e) => (rowRefs.current[p.id] = e)}
              key={p.id}
              config={config}
              property={p}
              isSelected={selected.includes(p.id)}
              onSelect={onSelect}
              highlighted={p.id === highlightedProperty}
              openPropertyDetails={openPropertyDetails}
              callPropertyProspects={() => callPropertyProspects(p)}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;

const PropertyRow = (
  { property, isSelected, onSelect, highlighted, openPropertyDetails, callPropertyProspects, config },
  ref,
) => {
  const bottomBorder =
    "tw-border-solid tw-border-gray-10 tw-border-b tw-border-r-0 tw-border-l-0 tw-border-t-0";
  const rightBorder = "tw-border-solid tw-border-gray-10 tw-border-b tw-border-r tw-border-l-0 tw-border-t-0";

  return (
    <tr
      ref={ref}
      className={`tw-h-72px tw-text-gray-75 ${isSelected || highlighted ? "tw-bg-tinted-gray-50" : "tw-bg-white"}`}
    >
      <td
        className={`tw-pl-20px tw-sticky tw-left-0 tw-z-10 ${
          isSelected || highlighted ? "tw-bg-tinted-gray-50" : "tw-bg-white"
        } tw-p-0 tw-whitespace-nowrap ${bottomBorder}`}
      >
        <div className="tw-flex tw-flex-row tw-justify-start tw-items-center">
          <Checkbox name="selected" checked={isSelected} onChange={() => onSelect(property)} />
        </div>
      </td>
      <td
        key="address"
        onClick={() => openPropertyDetails(property.id)}
        className={`tw-sticky tw-left-[34px] tw-cursor-pointer tw-z-10 ${
          isSelected || highlighted ? "tw-bg-tinted-gray-50" : "tw-bg-white"
        } tw-px-20px tw-whitespace-nowrap ${rightBorder}`}
      >
        <div className="tw-block tw-text-left">
          <p className="tw-text-gray-75 tw-m-0">{property.streetAddress}</p>
          <p className="tw-text-gray-75 tw-m-0">{`${property.city}, ${property.state} ${property.postalCode}`}</p>
        </div>
      </td>
      <PropertyCell>
        <div className="tw-flex tw-flex-col">
          <span className="tw-text-gray-50 tw-font-semibold">{property.status}</span>
          <span>{property.statusDateDisplay}</span>
        </div>
      </PropertyCell>
      <PropertyCell>
        {!property.recommendedProspect.name && !property.recommendedProspect.phone ? (
          <span className="tw-text-12px tw-text-gray-50">
            No prospect{config.showAllPersonsColumn ? "" : "s"} found
            <br />
            for this property
          </span>
        ) : (
          <div className="tw-flex tw-flex-col">
            <span
              className={`tw-max-w-[240px] tw-overflow-hidden tw-overflow-ellipsis ${prospectNameColor(property.recommendedProspect)}`}
            >
              {property.recommendedProspect.name}
            </span>
            <div className="tw-flex tw-flex-row tw-gap-6px">
              <span className="tw-text-12px">{property.recommendedProspect.phoneFormatted || "--"}</span>
              <Dnc show={property.recommendedProspect.isDnc} />
            </div>
          </div>
        )}
      </PropertyCell>
      <PropertyCell>
        <IconButton
          onClick={callPropertyProspects}
          schema="primary"
          size="small"
          disabled={!property.hasAnyPhoneNumbers}
        >
          <PhoneSolidV6 size="s" />
        </IconButton>
      </PropertyCell>
      {config.showAllPersonsColumn ? (
        <PropertyCell justify="tw-justify-center">
          {property.prospects.length ? (
            <Tooltip
              placement="right"
              innerClassName="tw-max-w-[200px]"
              multiline
              trigger={<AllProspects property={property} openPropertyDetails={openPropertyDetails} />}
              content={property.prospects.map((pp) => pp.name).join(", ")}
            />
          ) : (
            <AllProspects property={property} openPropertyDetails={openPropertyDetails} />
          )}
        </PropertyCell>
      ) : null}
      <PropertyCell justify="tw-justify-center">
        <div className="tw-flex tw-gap-16px tw-text-gray-30">
          <PhoneArrowUpRightSolidV6 size="l" />
          {/* <MailboxSolidV6 size="l" /> */}
        </div>
      </PropertyCell>
      <PropertyCell justify="tw-justify-end">{property.listPriceFormatted}</PropertyCell>
      {config.showDaysOnMarketColumn ? (
        <PropertyCell justify="tw-justify-end">{property.daysOnMarket}</PropertyCell>
      ) : null}
      <PropertyCell justify="tw-justify-end">{property.beds}</PropertyCell>
      <PropertyCell justify="tw-justify-end">{property.baths}</PropertyCell>
      <PropertyCell justify="tw-justify-end">{property.sqftFormatted}</PropertyCell>
      <PropertyCell justify="tw-justify-end">{property.lotSize}</PropertyCell>
      <PropertyCell justify="tw-justify-end">{property.yearBuilt}</PropertyCell>
      <PropertyCell>{property.propertyType}</PropertyCell>
    </tr>
  );
};

const RefRow = forwardRef(PropertyRow);

const PropertyCell = ({ children, justify }) => {
  return (
    <td className="tw-relative tw-px-20px tw-whitespace-nowrap tw-border-solid tw-border-gray-10 tw-border-b tw-border-r-0 tw-border-l-0 tw-border-t-0">
      <div className={`tw-flex tw-flex-row ${justify || "tw-justify-start"}`}>{children}</div>
    </td>
  );
};

const AllProspects = ({ property, openPropertyDetails }) => {
  const hoverStyle =
    "hover:tw-rounded hover:tw-border hover:tw-border-solid hover:tw-border-theme-primary-disabled hover:tw-no-underline";
  const activeStyle =
    "active:tw-rounded active:tw-border active:tw-border-solid active:tw-border-theme-primary active:tw-no-underline";

  return (
    <div
      className={`tw-flex tw-flex-col tw-items-center tw-cursor-pointer tw-p-8px ${hoverStyle} ${activeStyle}`}
      onClick={() => openPropertyDetails(property.id)}
    >
      <span
        className={`${property.prospects.length ? "tw-text-theme-primary" : "tw-text-theme-primary-disabled"} tw-font-bold`}
      >
        {property.prospects.length}
      </span>
      <span className="tw-text-12px tw-text-gray-50 tw-font-semibold">Prospects</span>
    </div>
  );
};
