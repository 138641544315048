import { useState, useEffect, useRef } from "react";
import axios from "axios";

import { getPostJsonHeaders } from "@shared/axiosHelpers";
import { responseDataToCamelCase } from "@shared/v2/caseTransformingAxios";
import CALL_STATES from "../../Dialer/utils/states";

export default (dialerServiceUrl) => {
  const abortController = useRef(null);
  const [session, setSession] = useState(null);
  const [conference, setConference] = useState(null);
  const [callState, setCallState] = useState(CALL_STATES.Init);
  const [disconnect, setDisconnect] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    return async () => {
      await endSession(dialerServiceUrl, session);
    };
  }, []);

  useEffect(() => {
    if (callState === CALL_STATES.Ended) {
      endCall().catch((e) => console.log(e));
    }
  }, [callState]);

  useEffect(() => {
    if (!session) {
      return () => {};
    }

    const consumer = ActionCable.createConsumer(`${dialerServiceUrl}/cable?token=${session.token}`);
    consumer.subscriptions.create(
      { channel: "ConferenceChannel", conference_uuid: session.conferenceUuid },
      {
        received(data) {
          const conference = responseDataToCamelCase(data.conference);
          handleCallStatusUpdate(conference);
        },
        connected() {
          this.perform("follow");
        },
      },
    );

    const cleanUp = () => {
      consumer.disconnect();
      setDisconnect(false);
    };

    if (disconnect) {
      cleanUp();
      return;
    }

    return () => {
      cleanUp();
    };
  }, [session, disconnect]);

  const startCall = async (prospects) => {
    setCallState(CALL_STATES.Init);

    if (session) {
      await endSession(dialerServiceUrl, session);
    }

    abortController.current = new AbortController();

    const newSession = await createSession(prospects, abortController.current);
    await startSession(dialerServiceUrl, newSession);

    setSession(newSession);
  };

  const endCall = async () => {
    if (!session && abortController.current) {
      abortController.current.abort();
      setCallState(CALL_STATES.Ended);
      return;
    }

    await endCurrentCall(dialerServiceUrl, session);
  };

  const logCall = async (outcome, notes) => {
    await saveOutcome(dialerServiceUrl, session, outcome, notes, conference);
    await endSession(dialerServiceUrl, session);
    setDisconnect(true);
  };

  const implode = async () => {
    if (!session && abortController.current) {
      abortController.current.abort();
      setCallState(CALL_STATES.Ended);
      return;
    }

    await endSession(dialerServiceUrl, session);

    setSession(null);
    setConference(null);
  };

  const handleCallStatusUpdate = (conference) => {
    setConference(conference);

    if (conference.currentParticipant?.humanizedCallStatus === "Ringing") {
      setCallState(CALL_STATES.Ringing);
    }

    if (conference.currentParticipant?.humanizedCallStatus === "In-progress") {
      setCallState(CALL_STATES.Connected);
    }

    if (
      conference.totalParticipants === 1 &&
      (conference.currentParticipant?.humanizedCallStatus === "Completed" ||
        conference.agentParticipant?.humanizedCallStatus === "Completed")
    ) {
      setCallState(CALL_STATES.Ended);
    }

    if (
      conference.agentParticipant?.humanizedCallStatus === "Canceled" ||
      conference.currentParticipant?.humanizedCallStatus === "Canceled"
    ) {
      setCallState(CALL_STATES.Ended);
    }

    if (conference.currentParticipant?.humanizedCallStatus === "Failed") {
      setError("Call to prospect failed.");
      setCallState(CALL_STATES.Ended);
    }
  };

  return {
    startCall,
    endCall,
    logCall,
    implode,
    callState,
    error,
  };
};

const createSession = async (prospects, abortController) => {
  const formatted = prospects?.map((p) => ({
    phone_number: p.phone,
    full_name: p.name,
  }));

  const options = {
    ...getPostJsonHeaders(),
    signal: abortController?.signal,
  };

  const { data } = await axios.post("/dialer/create_dialer_session", { prospects: formatted }, options);

  return data;
};

const startSession = async (dialerServiceUrl, session) => {
  if (!session) {
    return;
  }

  await axios.post(`${dialerServiceUrl}/dialer/start_dialer_session`, { token: session.token });
};

const saveOutcome = async (dialerServiceUrl, session, outcome, notes, conference) => {
  if (!session) {
    return;
  }

  const data = {
    outcome,
    comment: notes,
    is_prospect_call: true,
    participant_id: conference?.currentParticipant.id,
    token: session.token,
  };

  await axios.post(`${dialerServiceUrl}/dialer/save_outcome`, data);
};

const endCurrentCall = async (dialerServiceUrl, session) => {
  if (!session) {
    return;
  }

  await axios.post(`${dialerServiceUrl}/dialer/end_current_call`, { token: session.token });
};

const endSession = async (dialerServiceUrl, session) => {
  if (!session) {
    return;
  }

  await axios.post(`${dialerServiceUrl}/dialer/end_dialer_session`, { token: session.token });
};
