import React, { Component } from "react";
import axios from "axios";
import Proptypes from "prop-types";
import { Col, Form, Modal, Row } from "react-bootstrap";
import AiAssistant from "@shared/AiAssistant";
import Input from "@shared/Input";
import { Dropdown } from "@shared/v1";
import RoleForTransaction from "./RoleForTransaction";
import TransactionAutocomplete from "./TransactionAutocomplete";
import modalCss from "../PersonDetail/components/modal.module.css";
import formCss from "../PersonDetail/components/form.module.css";
import buttons from "../PersonDetail/components/button.module.css";
import transactionCss from "../PersonDetail/components/transactions.module.css";
import HeaderTitle from "../PersonDetail/components/modals/HeaderTitle";

export const TRANSACTION_STATUS = [
  { label: "Pipeline", value: "pipeline" },
  { label: "Coming Soon", value: "coming soon" },
  { label: "Active", value: "active" },
  { label: "Pending", value: "pending" },
  { label: "Closed", value: "sold" },
  { label: "Expired", value: "expired" },
  { label: "Withdrawn", value: "withdrawn" },
  { label: "Canceled", value: "canceled" },
  { label: "Archived", value: "archived" },
];

const ERROR_LIST = {
  transactionTitle: "Transaction Title can't be blank",
  streetAddress: "Street Address can't be blank",
  postalCode: "ZIP / Postal Code can't be blank",
  source: "Source is not included in the list",
};

class ListingTransactionModal extends Component {
  constructor(props) {
    super(props);
    const { defaultPropertyType, propertyTypes, defaultStatus } = this.props;
    this.state = {
      mlsIdsState: [],
      streetAddress: "",
      propertyType: defaultPropertyType,
      propertyTypesState: propertyTypes,
      source: null,
      expirationDate: undefined,
      postalCode: "",
      price: "",
      mlsNumber: "",
      status: defaultStatus || { label: "Pipeline", value: "pipeline" },
      beds: "",
      baths: "",
      sqFt: "",
      description: "",
      photos: [],
      city: "",
      state: "",
      transactionTitle: "",
      addRole: false,
      availableRoles: [],
      primaryPosition: 0,
      agentsOnTransaction: [],
      submitDisabled: false,
      validationErrorHtml: "",
      closePrice: null,
    };
  }

  loadDependencies = async () => {
    const promises = [this.loadRoles(), this.loadMls()];

    await Promise.all(promises);
  };

  loadRoles = async () => {
    const { transactionType } = this.props;

    const path =
      transactionType === "BuyerListing"
        ? "/roles/pending_transaction_roles"
        : "/roles/listing_transaction_roles";

    const response = await axios.get(path, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    let defaultRoles = response.data.default_roles;
    const { roles } = response.data;

    // Reorder listing or buyer's agent role to be first
    if (transactionType === "BuyerListing") {
      const buyersAgentRole = roles.find((role) => role.label === "Buyer's Agent");
      defaultRoles = [
        buyersAgentRole,
        ...defaultRoles.filter((defaultRole) => defaultRole.label !== "Buyer's Agent"),
      ];
    } else {
      const listingAgentRole = roles.find((role) => role.label === "Listing Agent");
      defaultRoles = [
        listingAgentRole,
        ...defaultRoles.filter((defaultRole) => defaultRole.label !== "Listing Agent"),
      ];
    }

    // Undefined shows up if the account doesn't have "Buyer's Agent" or "Listing Agent"
    defaultRoles = defaultRoles.filter((defaultRole) => defaultRole !== undefined);

    this.setState({
      availableRoles: roles.filter(
        (role) => !defaultRoles.some((defaultRole) => role.value === defaultRole.value),
      ),
      agentsOnTransaction: defaultRoles.map((role, index) => ({
        ...(role.agents[0].label.includes("Role Default") ? role.agents[0] : null),
        roleId: role.value,
        visible: "false",
        primary: index === 0 ? "true" : "false",
      })),
      roles,
    });
  };

  loadMls = async () => {
    const { currentUserId } = this.props;
    const { mlsIdsState } = this.state;
    if (mlsIdsState.length) {
      return;
    }
    // This route throws a 500 when there are no MLS IDs which...shouldn't happen
    // So this try/catch is necessary for an otherwise normal response
    try {
      const response = await axios.get(`/users/${currentUserId}/mls_ids`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      this.setState({ mlsIdsState: response.data.ids });
    } catch (err) {
      console.log("Error loading MLS IDs:", err);
    }
  };

  onListingFieldUpdate = (e) => {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  };

  handleVisibilitySelect = (visible, roleId) => {
    const { agentsOnTransaction } = this.state;
    const newAgentsOnTransaction = agentsOnTransaction.slice();
    const agent = newAgentsOnTransaction.find((a) => a.roleId === roleId);
    if (agent) {
      agent.visible = visible;
      this.setState({ agentsOnTransaction: newAgentsOnTransaction });
    }
  };

  handleAgentSelect = (agentId, roleId) => {
    const { agentsOnTransaction } = this.state;
    const newAgentsOnTransaction = agentsOnTransaction.slice();
    const agent = newAgentsOnTransaction.find((a) => a.roleId === roleId);
    if (agent) {
      agent.value = agentId;
      this.setState({ agentsOnTransaction: newAgentsOnTransaction });
    }
  };

  handleSourceSelect = (value) => {
    this.setState({ source: value });
  };

  handlePropertyTypeSelect = (propertyType) => {
    this.setState({ propertyType });
  };

  handleExpirationDateChange = (date) => {
    this.setState({ expirationDate: date });
  };

  onRoleSelect = ({ value }) => {
    const { roles, agentsOnTransaction } = this.state;
    let defaultAgent = roles.find((role) => role.value === value).agents[0];
    defaultAgent = defaultAgent.label.includes("Role Default") ? defaultAgent : null;
    const newAgentRole = { ...defaultAgent, visible: "false", roleId: value, primary: "false" };

    if (!agentsOnTransaction.length) {
      newAgentRole.primary = "true";
    }

    this.setState((prevState) => ({
      addRole: false,
      availableRoles: prevState.availableRoles.filter((r) => r.value !== value),
      agentsOnTransaction: [...prevState.agentsOnTransaction, newAgentRole],
    }));
  };

  handleRemoveRole = (roleId) => {
    const { roles, agentsOnTransaction } = this.state;
    const matchingRole = roles.find((role) => role.value === roleId);
    const matchingAgent = agentsOnTransaction.find((agent) => agent.roleId === roleId);
    const newAgentsOnTransaction = agentsOnTransaction.filter((agent) => agent.roleId !== roleId);

    if (matchingAgent.primary === "true" && newAgentsOnTransaction.length) {
      newAgentsOnTransaction[0].primary = "true";
    }

    this.setState((prevState) => ({
      availableRoles: [...prevState.availableRoles, matchingRole],
      agentsOnTransaction: newAgentsOnTransaction,
    }));
  };

  setPrimaryAgent = (roleId) => {
    const { agentsOnTransaction } = this.state;
    const newAgentsOnTransaction = agentsOnTransaction.slice();
    // eslint-disable-next-line no-restricted-syntax
    for (const agent of newAgentsOnTransaction) {
      agent.primary = agent.roleId == roleId ? "true" : "false";
    }

    this.setState({ agentsOnTransaction: newAgentsOnTransaction });
  };

  clearListing = () => {
    this.setState({
      selectedBlossorId: "",
    });
  };

  isPipeline = () => {
    const { status } = this.state;
    return status.value === "pipeline";
  };

  selectListing = async (term) => {
    const response = await axios.get(`/listings_api?blossor_id=${term.key}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const listingPropertyType = response.data.standardPropertyType || response.data.propertyType;
    const { propertyTypesState } = this.state;
    const selectedPropertyType = propertyTypesState.find((pt) => pt.label === listingPropertyType) || {
      label: listingPropertyType,
      value: -1,
    };

    this.setState((prevState) => ({
      selectedBlossorId: term.key,
      streetAddress: response.data.streetAddress,
      postalCode: response.data.postalCode || prevState.postalCode,
      price: response.data.price || prevState.price,
      propertyTypesState:
        selectedPropertyType.value === -1
          ? [...prevState.propertyTypesState, selectedPropertyType]
          : prevState.propertyTypesState,
      propertyType: selectedPropertyType,
      mlsNumber: response.data.mlsNum,
      beds: response.data.bedrooms || prevState.beds,
      baths: response.data.bathsTotalDecimal || prevState.baths,
      sqFt: response.data.squareFeet || prevState.sqFt,
      description: response.data.remarks,
      photos: response.data.photos,
      city: response.data.city,
      state: response.data.state,
    }));
  };

  handleStatusSelect = (status) => {
    this.setState({ status });
  };

  isInvalidSubmit = () => {
    const {
      status: { value },
      transactionTitle, // pipeline
      streetAddress,
      postalCode,
      source, // pipeline && non
    } = this.state;

    const htmlErrors = [];

    if (value === "pipeline") {
      if (!transactionTitle.trim()) {
        htmlErrors.push(ERROR_LIST.transactionTitle);
      }
    } else {
      if (!streetAddress.trim()) {
        htmlErrors.push(ERROR_LIST.streetAddress);
      }
      if (!postalCode.trim()) {
        htmlErrors.push(ERROR_LIST.postalCode);
      }
    }

    if (!source) {
      htmlErrors.push(ERROR_LIST.source);
    }

    if (htmlErrors.length) {
      const htmlToShow = (
        <div
          className="alert alert-danger"
          role="alert"
          dangerouslySetInnerHTML={{ __html: `<dl><dt>${htmlErrors.join("</dt></dl><dl><dt>")}</dl></dt>` }}
        />
      );
      this.setState({ submitDisabled: false, validationErrorHtml: htmlToShow });
      return true;
    }

    return false;
  };

  handleSubmit = async (publish) => {
    if (this.isInvalidSubmit()) return;
    const { transactionType, newMilestoneId } = this.props;
    this.setState({ submitDisabled: true });
    const {
      propertyType,
      status,
      price,
      mlsNumber,
      transactionTitle,
      closePrice,
      expirationDate,
      streetAddress,
      city,
      state,
      postalCode,
      agentsOnTransaction,
      photos,
      beds,
      baths,
      sqFt,
      source,
      description,
    } = this.state;
    // Create property type if it doesn't already exist
    if (propertyType.value === -1) {
      const propertyTypeFormData = new FormData();
      propertyTypeFormData.append("utf8", "✓");
      propertyTypeFormData.append("authenticity_token", ReactOnRails.authenticityToken());
      propertyTypeFormData.append("property_type[name]", propertyType.label);

      const response = await axios.post("/property_types.json", propertyTypeFormData);

      this.setState({ propertyType: { label: response.data.name, value: response.data.id } });
    }

    const formData = new FormData();

    const listingClosePrice = `listing[${status.value === "sold" ? "close_price" : "est_close_price"}]`;

    formData.append("utf8", "✓");
    formData.append("authenticity_token", ReactOnRails.authenticityToken());

    formData.append("listing[type]", transactionType);
    formData.append("property_type", propertyType.value);
    formData.append("listing[price]", price);
    formData.append("listing[mls_number]", mlsNumber);
    formData.append("listing[status]", status.value);
    formData.append("listing[title]", transactionTitle);
    formData.append(listingClosePrice, closePrice);

    formData.append("listing[milestonable_milestones_attributes][0][new_milestone_id]", newMilestoneId);
    formData.append("listing[milestonable_milestones_attributes][0][date]", expirationDate);

    formData.append("listing[address_attributes][street_address]", streetAddress);
    formData.append("listing[address_attributes][city]", city);
    formData.append("listing[address_attributes][locality]", state);
    formData.append("listing[address_attributes][postal_code]", postalCode);
    formData.append("listing[address_attributes][address_type]", "listing");

    for (let i = 0; i < agentsOnTransaction.length; i += 1) {
      const agent = agentsOnTransaction[i];

      formData.append(`listing[team_members_attributes][${agent.value}${i}][person_id]`, agent.value);
      formData.append(`listing[team_members_attributes][${agent.value}${i}][role_id]`, agent.roleId);
      formData.append(
        `listing[team_members_attributes][${agent.value}${i}][visible_to_public]`,
        agent.visible,
      );
      formData.append(`listing[team_members_attributes][${agent.value}${i}][primary]`, agent.primary);
    }

    for (let i = 0; i < photos.length; i += 1) {
      formData.append("photo_urls[]", photos[i].url);
    }

    formData.append("add_new_role_id", "");

    formData.append("listing[details_attributes][][name]", "beds");
    formData.append("listing[details_attributes][][value]", beds);

    formData.append("listing[details_attributes][][name]", "baths");
    formData.append("listing[details_attributes][][value]", baths);

    formData.append("listing[details_attributes][][name]", "sqft");
    formData.append("listing[details_attributes][][value]", sqFt);

    formData.append("listing[details_attributes][][_destroy]", false);

    formData.append("listing[source_id]", source?.value);
    formData.append("listing[description]", description);
    formData.append("listing[published]", publish && transactionType !== "pending");

    if (transactionType === "BuyerListing") {
      formData.append("listing[only_pending_info]", "true");
    }

    try {
      await axios.post("/listings/validate_new_listing_steps", formData);
      this.setState({ validationErrorHtml: "" });
    } catch (error) {
      // Well this is scary...
      const html = error.response.data.content;
      const htmlToShow = (
        <div className="alert alert-danger" role="alert" dangerouslySetInnerHTML={{ __html: html }} />
      );
      this.setState({ submitDisabled: false, validationErrorHtml: htmlToShow });
      return;
    }

    try {
      const createResponse = await axios.post("/listings", formData);
      window.location.replace(createResponse.data);
    } catch (error) {
      this.setState({ submitDisabled: false });
    }
  };

  render() {
    const { propertyTypes, transactionType, closeModal, mlsIds, sources } = this.props;
    const {
      mlsIdsState,
      streetAddress,
      propertyType,
      source,
      postalCode,
      price,
      mlsNumber,
      status,
      beds,
      baths,
      sqFt,
      description,
      city,
      state,
      transactionTitle,
      roles,
      primaryPosition,
      agentsOnTransaction,
      validationErrorHtml,
      closePrice,
      addRole,
      availableRoles,
      submitDisabled,
      selectedBlossorId,
    } = this.state;
    return (
      <Modal
        id="listing-transaction-modal"
        className="person-details-modal"
        show
        onShow={this.loadDependencies}
        enforceFocus={false}
      >
        <Modal.Header>
          <Modal.Title>
            <HeaderTitle title="Add Listing Information" onClose={closeModal} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={transactionCss.modalScroll}>
          {validationErrorHtml}
          <Form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div data-cy="listing-create-modal-0">
              <div className={mlsIdsState.length === 0 ? "hidden" : ""}>
                <TransactionAutocomplete
                  mlsIds={mlsIds}
                  selectedBlossorId={selectedBlossorId}
                  selectListing={this.selectListing}
                  clearListing={this.clearListing}
                  listingData={this.state}
                />
                <Row>
                  <Col xs={12}>
                    <hr className={`${modalCss.divider} ${modalCss.pB24}`} />
                  </Col>
                </Row>
              </div>
              <Row>
                <Col xs={6} className="form-group">
                  <label htmlFor="status" className={`${formCss.formLabel2} ${formCss.required}`}>
                    TRANSACTION STATUS
                  </label>
                  <Dropdown
                    isSearchable={false}
                    isClearable={false}
                    options={TRANSACTION_STATUS}
                    value={status}
                    onChange={this.handleStatusSelect}
                    variant="flatBlueishGray"
                  />
                </Col>
                <Col xs={6} className="form-group">
                  <label
                    htmlFor="transactionTitle"
                    className={`${formCss.formLabel2} ${this.isPipeline() && formCss.required}`}
                  >
                    TRANSACTION TITLE
                  </label>
                  <Input
                    type="text"
                    className={formCss.formControl}
                    name="transactionTitle"
                    value={transactionTitle}
                    onChange={this.onListingFieldUpdate}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={6} className="form-group">
                  <label htmlFor="propertyType" className={`${formCss.formLabel2} ${formCss.required}`}>
                    PROPERTY TYPE
                  </label>
                  <Dropdown
                    isSearchable={false}
                    isClearable={false}
                    options={propertyTypes}
                    value={propertyType}
                    onChange={this.handlePropertyTypeSelect}
                    variant="flatBlueishGray"
                  />
                </Col>
                <Col xs={6} className="form-group">
                  <label
                    htmlFor="streetAddress"
                    className={`${formCss.formLabel2} ${!this.isPipeline() && formCss.required}`}
                  >
                    STREET ADDRESS
                  </label>
                  <Input
                    type="text"
                    className={formCss.formControl}
                    name="streetAddress"
                    value={streetAddress}
                    onChange={this.onListingFieldUpdate}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={6} className="form-group">
                  <label htmlFor="city" className={formCss.formLabel2}>
                    CITY
                  </label>
                  <Input
                    type="text"
                    className={formCss.formControl}
                    name="city"
                    value={city}
                    onChange={this.onListingFieldUpdate}
                  />
                </Col>
                <Col xs={6} className="form-group">
                  <label htmlFor="state" className={formCss.formLabel2}>
                    STATE/PROVINCE
                  </label>
                  <Input
                    type="text"
                    className={formCss.formControl}
                    name="state"
                    value={state}
                    onChange={this.onListingFieldUpdate}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={6} className="form-group">
                  <label
                    htmlFor="postalCode"
                    className={`${formCss.formLabel2} ${!this.isPipeline() && formCss.required}`}
                  >
                    ZIP / POSTAL CODE
                  </label>
                  <Input
                    type="text"
                    className={formCss.formControl}
                    name="postalCode"
                    value={postalCode}
                    onChange={this.onListingFieldUpdate}
                  />
                </Col>
                <Col xs={6} className="form-group">
                  <label htmlFor="mlsNumber" className={formCss.formLabel2}>
                    MLS #
                  </label>
                  <Input
                    type="text"
                    className={formCss.formControl}
                    name="mlsNumber"
                    value={mlsNumber}
                    onChange={this.onListingFieldUpdate}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={6} className="form-group">
                  <label htmlFor="price" className={formCss.formLabel2}>
                    LISTING PRICE
                  </label>
                  <Input
                    type="text"
                    className={formCss.formControl}
                    name="price"
                    value={price}
                    onChange={this.onListingFieldUpdate}
                  />
                </Col>
                <Col xs={6} className="form-group">
                  <label htmlFor="closePrice" className={formCss.formLabel2}>{`${
                    status.value === "sold" ? "" : "ESTIMATED "
                  }CLOSE PRICE`}</label>
                  <Input
                    type="text"
                    className={formCss.formControl}
                    name="closePrice"
                    value={closePrice}
                    onChange={this.onListingFieldUpdate}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={6} className="form-group">
                  <label htmlFor="beds" className={formCss.formLabel2}>
                    BEDS
                  </label>
                  <Input
                    type="text"
                    className={formCss.formControl}
                    name="beds"
                    value={beds}
                    onChange={this.onListingFieldUpdate}
                  />
                </Col>
                <Col xs={6} className="form-group">
                  <label htmlFor="baths" className={formCss.formLabel2}>
                    BATHS
                  </label>
                  <Input
                    type="text"
                    className={formCss.formControl}
                    name="baths"
                    value={baths}
                    onChange={this.onListingFieldUpdate}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={6} className="form-group">
                  <label htmlFor="sqFt" className={formCss.formLabel2}>
                    SQFT
                  </label>
                  <Input
                    type="text"
                    className={formCss.formControl}
                    name="sqFt"
                    value={sqFt}
                    onChange={this.onListingFieldUpdate}
                  />
                </Col>
                <Col xs={6} className="form-group">
                  <label htmlFor="source" className={`${formCss.formLabel2} ${formCss.required}`}>
                    SOURCE
                  </label>
                  <Dropdown
                    isSearchable
                    isClearable={false}
                    options={sources}
                    value={source}
                    onChange={this.handleSourceSelect}
                    variant="flatBlueishGray"
                  />
                </Col>
              </Row>
              <Row className={modalCss.pB15}>
                <Col xs={12}>
                  <div className="tw-flex tw-justify-between tw-items-end tw-mb-[8px]">
                    <label htmlFor="description" className={`${formCss.formLabel2} !tw-mb-0`}>
                      LISTING DESCRIPTION
                    </label>
                    <AiAssistant
                      textareaId="transaction-description-input"
                      messageType="Generic"
                      onInsertClick={(text) =>
                        this.onListingFieldUpdate({ target: { name: "description", value: text } })
                      }
                    />
                  </div>
                  <textarea
                    id="transaction-description-input"
                    className={formCss.formControl}
                    name="description"
                    value={description}
                    onChange={this.onListingFieldUpdate}
                    rows="6"
                  />
                </Col>
              </Row>

              {agentsOnTransaction.map((agent, position) => (
                <RoleForTransaction
                  key={agent.roleId}
                  role={roles.find((role) => role.value === agent.roleId)}
                  visible={agent.visible}
                  selectedAgentId={agent.value}
                  position={position}
                  primaryPosition={primaryPosition}
                  primary={agent.primary}
                  showVisibility={transactionType !== "BuyerListing"}
                  setPrimaryAgent={this.setPrimaryAgent}
                  handleAgentSelect={this.handleAgentSelect}
                  handleVisibilitySelect={this.handleVisibilitySelect}
                  handleRemoveRole={this.handleRemoveRole}
                />
              ))}

              {addRole && (
                <Row>
                  <Col xs={6} className="form-group">
                    <label htmlFor="availableRoles" className={formCss.formLabel2}>
                      ROLE
                    </label>
                    <Dropdown
                      isSearchable
                      isClearable={false}
                      options={availableRoles}
                      onChange={this.onRoleSelect}
                      variant="flatBlueishGray"
                    />
                  </Col>
                </Row>
              )}
              <Row className={modalCss.pB16}>
                <Col xs={12}>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    role="button"
                    tabIndex="0"
                    onKeyDown={() => {
                      this.setState((prevState) => ({ addRole: !prevState.addRole }));
                    }}
                    className={`${buttons.btn} ${buttons.btnSm} ${buttons.btnBlue}`}
                    onClick={() => {
                      this.setState((prevState) => ({ addRole: !prevState.addRole }));
                    }}
                  >
                    ADD ROLE <i className={`fa fa-plus ${transactionCss.addRolePlus}`} />
                  </a>
                </Col>
              </Row>
              <Row className={modalCss.pT32}>
                <Col xs={12}>
                  <button
                    type="button"
                    className={`${buttons.btn} ${buttons.btnLg} ${buttons.btnGray}`}
                    onClick={(e) => {
                      e.preventDefault();
                      closeModal();
                    }}
                  >
                    CANCEL
                  </button>
                  <button
                    type="submit"
                    className={`${buttons.btn} ${buttons.btnLg} ${buttons.btnBlue} pull-right`}
                    disabled={submitDisabled}
                    onClick={() => this.handleSubmit(true)}
                  >
                    CREATE TRANSACTION
                  </button>
                  <button
                    type="submit"
                    className={`${buttons.btn} ${buttons.btnLg} ${buttons.btnSecondary} ${modalCss.mR16} pull-right`}
                    disabled={submitDisabled}
                    onClick={() => this.handleSubmit(false)}
                  >
                    SAVE AS DRAFT
                  </button>
                </Col>
              </Row>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ListingTransactionModal;
export const optionShape = Proptypes.shape({
  label: Proptypes.string,
  value: Proptypes.oneOfType([Proptypes.string, Proptypes.number]),
});
ListingTransactionModal.propTypes = {
  defaultPropertyType: optionShape,
  propertyTypes: Proptypes.arrayOf(optionShape),
  defaultStatus: optionShape,
  currentUserId: Proptypes.oneOfType([Proptypes.string, Proptypes.number]),
  transactionType: Proptypes.string,
  closeModal: Proptypes.func,
  sources: Proptypes.arrayOf(optionShape),
  mlsIds: Proptypes.oneOfType([Proptypes.string, Proptypes.number]),
  newMilestoneId: Proptypes.oneOfType([Proptypes.string, Proptypes.number]),
};

ListingTransactionModal.defaultProps = {
  defaultPropertyType: null,
  propertyTypes: [],
  defaultStatus: null,
  currentUserId: null,
  transactionType: "",
  closeModal: null,
  sources: [],
  mlsIds: null,
  newMilestoneId: null,
};
