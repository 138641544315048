import React, { useEffect, useState } from "react";
import { debounce } from "lodash";

import Button from "@shared/v2/Button";
import IntersectionObserverNode from "@shared/v2/IntersectionObserverNode";
import TextButton from "@shared/v2/TextButton";
import { CloseButton } from "@shared/v2/Modal/ModalHeader";
import TextInput from "@shared/v2/TextInput";
import { MagnifyingGlassSolidV6, ThumbtackRegularV6, ThumbtackSolidV6 } from "@shared/v2/Icomoon";
import Portal from "@shared/v1/Portal";
import Loading from "../../../Goals/components/Loader";
import { useFeatureFlags, useTheme } from "../../../reducers/layoutReducer/selectors";
import {
  FavPropertyActivity,
  HomeValueActivity,
  LeadTransferActivity,
  MarketReportActivity,
  NewLeadActivity,
  NewPondActivity,
  NewTaskActivity,
  NoteMentionActivity,
  QuestionsActivity,
  ReturnSiteActivity,
  SavedSearchActivity,
  ShowingRequestActivity,
  SubmittedFormActivity,
  TextActivity,
  ViewedPropertyActivity,
} from "./Activities";
import useActivities from "./hooks/useActivities";

const EmptyActivity = () => <div />;

const ACTIVITY_MAPPING = {
  assigned_task: NewTaskActivity,
  cash_offer_submitted: SubmittedFormActivity,
  cma_requested: SubmittedFormActivity,
  contact_form_existing_lead: SubmittedFormActivity,
  contact_form_general: SubmittedFormActivity,
  contact_form_questions_about: QuestionsActivity,
  contact_form_start_offer: SubmittedFormActivity,
  daily_tasks: EmptyActivity,
  group_message: TextActivity,
  home_value_submitted: HomeValueActivity,
  home_value: HomeValueActivity,
  lead_pond_contact_form_questions_about: QuestionsActivity,
  lead_pond_home_value_submitted: HomeValueActivity,
  lead_pond_home_value: HomeValueActivity,
  lead_pond_mention: NoteMentionActivity,
  lead_pond_property_viewed_x_times: ViewedPropertyActivity,
  lead_pond_registration: NewPondActivity,
  lead_pond_return_after_30_days: ReturnSiteActivity,
  lead_pond_schedule_showing: ShowingRequestActivity,
  lead_property_status_change: EmptyActivity,
  lead_registration: NewLeadActivity,
  lead_return_after_30_days: ReturnSiteActivity,
  lead_transfer: LeadTransferActivity,
  listing_alert: EmptyActivity,
  market_report: MarketReportActivity,
  mention: NoteMentionActivity,
  new_market_report: MarketReportActivity,
  new_message: EmptyActivity,
  prequalification_request: SubmittedFormActivity,
  price_increased: HomeValueActivity,
  price_reduced: HomeValueActivity,
  property_sent: EmptyActivity,
  property_viewed_x_times: ViewedPropertyActivity,
  question_submitted: QuestionsActivity,
  saved_property: FavPropertyActivity,
  saved_search: SavedSearchActivity,
  schedule_showing: ShowingRequestActivity,
  sell_my_home_submitted: SubmittedFormActivity,
  selling_page_visited: EmptyActivity,
  status_change: EmptyActivity,
  text_message: TextActivity,
  unlock_equity_requested: SubmittedFormActivity,
  updated_home_value: HomeValueActivity,
  valuation_requested: SubmittedFormActivity,
};

const TACK_WINDOW_WIDTH = 1920;

const ActivitiesFeed = () => {
  const isPlace = useTheme()?.name === "place-theme";
  const featureFlags = useFeatureFlags();
  const [tack, setTack] = useState(JSON.parse(localStorage.getItem("activityFeed:tack") || "false"));
  const [showTack, setShowTack] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showActivityFeed, setShowActivityFeed] = useState(tack && windowWidth > TACK_WINDOW_WIDTH);
  const [query, setQuery] = useState("");
  const { actions, activityGroups, loading, unreadCount } = useActivities({ query, show: showActivityFeed });
  const ThumbtackComponent = tack ? ThumbtackSolidV6 : ThumbtackRegularV6;

  const toggleActivityFeed = () => setShowActivityFeed(!showActivityFeed);

  useEffect(() => {
    if (showActivityFeed) {
      // observe window width
      const handleResize = debounce(() => {
        setWindowWidth(window.innerWidth);
      }, 150);
      window.addEventListener("resize", handleResize);
      handleResize();

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
    return () => {};
  }, [showActivityFeed]);

  useEffect(() => {
    setShowTack(windowWidth > TACK_WINDOW_WIDTH);
  }, [windowWidth]);

  useEffect(() => {
    setTack(showTack);
  }, [showTack]);

  return (
    <>
      <Button
        className={`tw-h-[36px] tw-w-[147px] tw-pl-[20px] tw-pr-[6px] tw-text-14d tw-font-semibold tw-normal-case tw-whitespace-nowrap tw-bg-white !tw-border-1px !tw-border-gray-75 !tw-border-solid tw-flex tw-gap-[6px] tw-items-center tw-ease-out tw-transition-all hover:tw-duration-150 active:tw-duration-50 
          ${
            isPlace
              ? "tw-text-white tw-bg-opacity-10 hover:!tw-bg-opacity-10 active:!tw-bg-white active:!tw-bg-opacity-30 active:!tw-border-[#578EFA] active:!tw-border-3px"
              : "!tw-text-[#4CA7A7] hover:!tw-bg-blue-10 active:!tw-bg-[#DEF3F3] "
          } 
            active:tw-pl-[20px] active:tw-border-[1px] active:!tw-border-transparent 
            ${showActivityFeed ? `${isPlace ? "!tw-border-[#578EFA]" : "!tw-bg-blue-10"}` : ""}          
        `}
        onClick={toggleActivityFeed}
      >
        Activity Feed
        <div
          className={`tw-rounded-full tw-bg-theme-primary tw-w-[24px] tw-h-[24px] tw-flex tw-items-center tw-justify-center tw-text-white ${unreadCount > 99 ? "tw-text-[10px]" : "tw-text-12d"}`}
        >
          {unreadCount > 99 ? "99+" : unreadCount}
        </div>
      </Button>
      <Portal portalId="activities-portal">
        <div
          className={`tw-max-w-[100vw] tw-w-[564px] tw-absolute tw-z-30 tw-shadow-[0px_6px_20px_2px_#33333329] tw-bg-white tw-flex tw-flex-col tw-transform tw-transition-transform tw-duration-[500ms] tw-ease-in-out tw-right-0 ${
            tack ? "!tw-relative" : "tw-h-[calc(100%-8px)]"
          } ${showActivityFeed ? "tw-translate-x-0" : "tw-translate-x-full"} ${
            !tack || showActivityFeed ? "" : "tw-ml-[-564px]"
          }`}
        >
          <div className="tw-flex tw-flex-col tw-gap-24px tw-p-[24px] tw-border-solid tw-border-0 tw-border-b tw-border-gray-10 tw-">
            <div className="tw-flex tw-justify-between">
              <span className="tw-text-18d tw-text-gray-base">Activity Feed</span>
              <div className="tw-flex tw-items-center tw-gap-8px">
                {showTack && (
                  <ThumbtackComponent
                    size="l"
                    onClick={() =>
                      setTack((t) => {
                        const newTack = !t;
                        localStorage.setItem("activityFeed:tack", JSON.stringify(newTack));
                        return newTack;
                      })
                    }
                    className="tw-cursor-pointer"
                  />
                )}
                <CloseButton onClick={toggleActivityFeed} />
              </div>
            </div>
            <div className="tw-flex tw-gap-24px tw-items-center">
              {featureFlags.activityFeedSearch && (
                <div className="tw-flex tw-flex-row tw-relative tw-flex-1">
                  <TextInput
                    containerClassName="tw-flex-1 tw-w"
                    className="tw-pr-32px"
                    placeholder="Search activity"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                  />
                  <MagnifyingGlassSolidV6
                    size="l"
                    className="tw-text-gray-30 tw-absolute tw-right-8px tw-top-10px"
                  />
                </div>
              )}
              <TextButton onClick={actions.markAllAsRead} className="tw-ml-auto">
                Mark All As Read
              </TextButton>
            </div>
          </div>
          <div className="tw-relative tw-flex-1 tw-flex tw-flex-col tw-overflow-hidden">
            {loading && <Loading />}
            <div className="tw-w-full tw-flex-1 tw-overflow-y-auto tw-bg-[#F9FBFB] tw-py-16px tw-px-24px tw-flex tw-flex-col tw-gap-16px">
              {activityGroups.map(({ date, activities }) => {
                if (activities.length === 0) return null;
                return (
                  <React.Fragment key={date}>
                    <div className="tw-text-14d tw-text-gray-50 tw-font-semibold">{date}</div>
                    <div className="tw-flex tw-flex-col tw-gap-8px">
                      {activities.map((activity) => {
                        const Activity = ACTIVITY_MAPPING[activity.notificationType];

                        if (!Activity) return null;

                        return (
                          <Activity
                            key={activity.id}
                            activity={activity}
                            onReadChange={() => actions.onReadChange(activity)}
                          />
                        );
                      })}
                    </div>
                  </React.Fragment>
                );
              })}
              {!loading && <IntersectionObserverNode onIntersect={() => actions.loadMore()} />}
            </div>
          </div>
        </div>
      </Portal>
    </>
  );
};

export default ActivitiesFeed;
