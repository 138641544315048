import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import AddressView from "@shared/AddressView";
import Alert from "@shared/v2/Alert";
import Button from "@shared/v2/Button";
import Modal from "@shared/v2/Modal";
import { PlusSolidV6 } from "@shared/v2/Icomoon";
import { updatePersonPromise } from "../../actions/updatePerson";
import { LOCALITY_LIST } from "../../shared/constants";

const CustomError = ({ error }) => {
  if (typeof error === "string") {
    return <Alert title="Error" text={error} />;
  }
  return <Alert title="Error" text="Whoops something went wrong, try again later." />;
};

CustomError.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]).isRequired,
};

class EditAddressesModal extends Component {
  constructor(props) {
    super(props);
    const editablePerson = _.cloneDeep(props.person);
    this.state = {
      editablePerson: {
        ...editablePerson,
        included: _.remove(editablePerson.included, (currentObject) => currentObject.id !== null),
      },
      errors: null,
      loading: false,
      selectedAddressTypes: [],
    };
  }

  componentDidMount() {
    const { person } = this.props;
    this.setState({
      selectedAddressTypes: person.included
        .filter((item) => item.type === "address" && item.id !== null)
        .map((item) => _.get(item, "attributes.address_type")),
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { person } = this.props;
    if (person !== nextProps.person) {
      this.setState({ editablePerson: JSON.parse(JSON.stringify(nextProps.person)) });
    }
  }

  handleSaveClick = () => {
    const { editablePerson } = this.state;
    const { updatePersonData, closeEditAddressModal } = this.props;
    this.setState({ loading: true });
    updatePersonPromise(editablePerson)
      .then((r) => {
        updatePersonData(r.data);
        closeEditAddressModal();
        this.setState({ loading: false, errors: null });
      })
      .catch((err) => {
        if (err.response.data.errors?.every((e) => e.code === "100")) {
          updatePersonData(editablePerson);
          closeEditAddressModal();
          this.setState({ loading: false, errors: null });
        } else {
          this.setState({ loading: false, errors: err.response.data.errors });
        }
      });
  };

  handleIncludedValueChange = (object, value, key) => {
    const { editablePerson } = this.state;
    const includedArray = editablePerson.included;
    const index = includedArray.indexOf(object);
    includedArray[index].attributes[key] = value;
    this.setState({
      editablePerson: {
        ...editablePerson,
        included: includedArray,
      },
    });
  };

  onAddAddressClick = () => {
    const { editablePerson, selectedAddressTypes } = this.state;
    this.setState({
      editablePerson: {
        ...editablePerson,
        included: [
          ...editablePerson.included,
          {
            type: "address",
            attributes: {
              address_type: this.labels()[0].value,
              city: "",
              locality: "",
              postal_code: "",
              street_address: "",
              tempId: Date.now(),
            },
          },
        ],
      },
      selectedAddressTypes: [...selectedAddressTypes, this.labels()[0].value],
    });
  };

  handleRemoveIncludedAttribute = (object) => {
    const { editablePerson } = this.state;
    const includedArray = _.cloneDeep(editablePerson.included);
    const index = includedArray.indexOf(object);
    includedArray.splice(index, 1);
    this.setState({
      editablePerson: {
        ...editablePerson,
        included: includedArray,
      },
    });
  };

  labels = () => {
    const { selectedAddressTypes } = this.state;
    let options = [
      { label: "Home", value: "home" },
      { label: "Work", value: "work" },
      { label: "Mailing", value: "mailing" },
      { label: "Investment", value: "investment" },
    ];

    options = options.filter((option) => !selectedAddressTypes.includes(option.value));

    return [...options, { label: "Other", value: "other" }];
  };

  updateIncluded = (tempIdOrId) => (newAttributes) => {
    this.setState((prevState) => {
      const editablePersonClone = _.cloneDeep(prevState.editablePerson);

      editablePersonClone.included.forEach((item) => {
        const itemId = _.get(item, "attributes.id");
        const itemTempId = _.get(item, "attributes.tempId");

        if (itemId === tempIdOrId || itemTempId === tempIdOrId) {
          _.merge(item.attributes, newAttributes);
        }
      });

      return { editablePerson: editablePersonClone };
    });
  };

  render() {
    const { editablePerson, errors, loading, selectedAddressTypes } = this.state;
    const { closeEditAddressModal, show } = this.props;

    return (
      <Modal
        id="edit-addresses-modal"
        className="tw-flex tw-items-center tw-justify-center"
        contentClassName="tw-max-w-[800px] tw-max-h-[90vh] tw-w-[100vw] tw-flex tw-flex-col tw-gap-[32px]"
        show={show}
        onHide={closeEditAddressModal}
      >
        <Modal.Header title="Edit Addresses" closeButton={!loading} />
        <Modal.Body className="tw-overflow-auto tw-flex-1 tw-flex tw-flex-col tw-gap-[24px]">
          {errors?.map((error, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <CustomError key={i} error={error} />
          ))}
          {editablePerson.included
            // eslint-disable-next-line no-underscore-dangle
            .filter((d) => d.type === "address" && !d.attributes._destroy)
            .map((address) => {
              const usableId = address.attributes.id || address.attributes.tempId;
              const addressType = address.attributes.address_type;

              // this returns a function that will update the included object with the new attributes
              const updateAddress = this.updateIncluded(usableId);
              return (
                <AddressView
                  currentAddress={{
                    ...address.attributes,
                    locality: _.toUpper(address.attributes.locality),
                  }}
                  options={_.uniqWith(
                    [{ label: _.capitalize(addressType), value: _.toLower(addressType) }, ...this.labels()],
                    _.isEqual,
                  )}
                  stateOptions={LOCALITY_LIST}
                  key={usableId}
                  onRemoveAddressHandlr={() => {
                    if (address.attributes.id) {
                      this.setState({
                        editablePerson: {
                          ...editablePerson,
                          included: editablePerson.included.map((item) => {
                            if (item.attributes.id === address.attributes.id) {
                              return {
                                ...item,
                                attributes: {
                                  ...item.attributes,
                                  _destroy: true,
                                },
                              };
                            }
                            return item;
                          }),
                        },
                      });
                      return;
                    }

                    this.handleRemoveIncludedAttribute(address);
                    // update the selected address types, remove the old one
                    this.setState({
                      selectedAddressTypes: [..._.without(selectedAddressTypes, addressType)],
                    });
                  }}
                  onTypeHandlr={(option) => {
                    updateAddress({ address_type: option?.value });
                    // update the selected address types, remove the old one and add the new one
                    this.setState({
                      selectedAddressTypes: [..._.without(selectedAddressTypes, addressType), option?.value],
                    });
                  }}
                  onStreetHandlr={(e) => updateAddress({ street_address: e.target.value })}
                  onCityHandlr={(e) => updateAddress({ city: e.target.value })}
                  onPostalCodeHandlr={(e) => updateAddress({ postal_code: e.target.value })}
                  onLocalityHandlr={(option) => updateAddress({ locality: option?.value })}
                  removable
                />
              );
            })}
          <div>
            <Button
              className="!tw-inline-flex tw-items-center tw-gap-[4px]"
              data-cy="edit-addresses-add-address"
              onClick={this.onAddAddressClick}
            >
              Add Address <PlusSolidV6 />
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer className="tw-flex tw-justify-between tw-gap-[8px]">
          <Button disabled={loading} schema="tertiary" size="medium" onClick={closeEditAddressModal}>
            Cancel
          </Button>
          <Button
            isLoading={loading}
            size="medium"
            data-cy="edit-addresses-save"
            onClick={this.handleSaveClick}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

EditAddressesModal.propTypes = {
  person: PropTypes.shape().isRequired,
  closeEditAddressModal: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  updatePersonData: PropTypes.func.isRequired,
};

export default EditAddressesModal;
