import React, { useEffect, useMemo, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import HubspotRequestDemoModal from "@shared/HubspotRequestDemoModal";
import AiSummary from "@shared/AiAssistant/AiSummary";
import Button from "@shared/v2/Button";
import { CheckSolidV6, HouseSolidV6, KeySolidV6 } from "@shared/v2/Icomoon";
import viewPhone from "@shared/models/Communications/viewPhone";
import useSimpleNavigation from "@shared/v2/useSimpleNavigation";
import * as actions from "../actions/personDetailActionCreators";
import * as creators from "../../AddMarketReportModal/actions/creators";
import PersonInteractions from "./PersonInteractions";
import PersonInfo from "./PersonInfo";
import AssignedTo from "./assigned_to/AssignedTo";
import Details from "./Details";
import ContactInfo from "./ContactInfo";
import Documents from "./Documents";
import Relationships from "./Relationships";
import Transactions from "./Transactions";
import WebActivity from "./WebActivity";
import HomeApp from "./HomeApp";
import AutoPlans from "./auto_plans/AutoPlans";
import ListingAlertCard from "./listing_alerts/ListingAlertCard";
import MarketReports from "./MarketReports";
import CmaReports from "./CmaReports";
import CardTasks from "./tasks/CardTasks";
import CardAppointments from "./tasks/CardAppointments";
import Projects from "./Projects";
import Mortgage from "./Mortgage";
import AddMarketReportModal from "../../AddMarketReportModal";
import CardNotes from "./notes/CardNotes";
import TimelinesApp from "../../Timelines/components/TimelinesApp";
import PersonDetailsModal from "../../Contacts/PersonDetailsModal";
import ContactMergeModal from "../../ContactMergeModal/ContactMergeModal";
import { useFeatureFlags } from "../../reducers/layoutReducer/selectors";
import AgreementsConfirmModal from "./AgreementsConfirmModal";
import Agreements from "./Agreements";
import ReferLenderModal from "./ReferLenderModal";
import { useSuccessMessageOpen } from "../../reducers/personalDetailReducer/personDetailReducer";
import SelectDocTypeModal, { DocButton } from "./modals/SelectDocTypeModal";
import { viewMarketReportApi } from "../../AddMarketReportModal/services";

const PersonDetail = ({
  allAgents,
  newMilestoneId,
  gmailUnsynced,
  emailTemplates,
  appointmentOutcomes,
  accountMilestoneNames,
  idxDataToken,
  idxDataUri,
}) => {
  const dispatch = useDispatch();
  const { pathname, push } = useSimpleNavigation();
  const editPersonModalOpen = useSelector((state) => state.personDetail.editPersonModalOpen);
  const person = useSelector((state) => state.personDetail.person);
  const error = useSelector((state) => state.personDetail.error);
  const featureFlags = useFeatureFlags();
  const [state, setState] = useState({
    platformCTAOpen: false,
    marketReportsRefreshKey: 0,
    scrollForm2: false,
  });
  const successMessageOpen = useSuccessMessageOpen();
  const primaryPhoneNumber = useMemo(
    () =>
      (person.included?.filter((i) => i.type === "phone_detail").map((i) => viewPhone(i)) || []).find(
        (i) => i.isPrimary,
      ),
    [person.included],
  );

  const marketReportPerson = useMemo(
    () => ({
      ...person,
      emails: person.included
        ?.filter((i) => i.type === "email_detail")
        .map((i) => ({
          address: i.attributes.value,
          category: i.attributes.detailable_type,
        })),
      addresses: person.included
        ?.filter((i) => i.type === "address" && i.id !== null)
        .map(
          (i) =>
            `${i.attributes?.street_address} ${i.attributes?.city}, ${i.attributes?.locality} ${i.attributes?.postal_code}`,
        ),
    }),
    [person.data],
  );

  const loadData = () => {
    const slug = pathname.split("/").pop();
    dispatch(actions.getPerson(slug));
    dispatch(actions.getNavLinks(slug));
    dispatch(actions.fetchProjects(slug));
  };

  useEffect(() => {
    loadData();
  }, [pathname]);

  const openPanel2 = async (marketReportId) => {
    const onFailure = (err) => dispatch(creators.getMarketReportError({ error: err }));
    const onSuccess = (response) => {
      if (response?.data?.error) {
        return onFailure(response?.data?.error);
      }
      return dispatch(creators.getMarketReportSuccess({ data: response.data }, 1));
    };
    const data = { id: marketReportId, primary_agent_uuid: person.data?.attributes.primary_agent_uuid };

    await viewMarketReportApi({ data, successCb: onSuccess, errorCb: onFailure });

    setState((prevState) => ({
      ...prevState,
      editMarketReportId: marketReportId,
      showAddModal: true,
      scrollForm2: true,
    }));
  };

  // *Note 1: this is for followup ticket: https://app.clickup.com/t/86duxrayc
  // const urlParams = new URLSearchParams(window.location.search);
  // const marketReportIdParam = urlParams.get("xx");
  // *xx is placeholder until we have the actual param name

  // useEffect(() => {
  //   if (marketReportIdParam && person.data?.attributes.primary_agent_uuid) {
  //     openPanel2(marketReportIdParam);
  //   }
  // }, [person.data?.attributes.primary_agent_uuid]);

  useEffect(() => {
    if (!person.data?.id) return;

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("edit")) {
      dispatch(actions.openEditPersonModal());
    }
  }, [person.data?.id]);

  useEffect(() => {
    const firstName = person.data?.attributes.first_name || "";
    const lastName = person.data?.attributes.last_name || "";
    document.title = `${firstName} ${lastName}`.trim() || "No Name";
  }, [person.data?.attributes.first_name, person.data?.attributes.last_name]);

  const openPlatformCTA = () => setState((prevState) => ({ ...prevState, platformCTAOpen: true }));

  const closePlatformCTA = () => setState((prevState) => ({ ...prevState, platformCTAOpen: false }));

  const editMarketReport = (id) => {
    setState((prevState) => ({ ...prevState, editMarketReportId: id, showAddModal: !!id }));
  };

  const toggleAddMarketModal = () => {
    dispatch(creators.setTab(0));
    setState((prevState) => ({
      marketReportsRefreshKey: prevState.showAddModal
        ? prevState.marketReportsRefreshKey + 1
        : prevState.marketReportsRefreshKey,
      showAddModal: !prevState.showAddModal,
      scrollForm2: false,
      editMarketReportId: null,
    }));
  };

  const hiddenUploadInput = useRef(null);
  const [docType, setDocType] = useState("");
  const [openAddAgreementModal, setOpenAddAgreementModal] = useState(false);
  const [openSelectDocModal, setOpenSelectDocModal] = useState(false);
  const [openAgreementModalConfirmation, setOpenAgreementModalConfirmation] = useState(false);
  const [openAgreementTypeModal, setOpenAgreementTypeModal] = useState(false);

  const setDoc = (documentType) => {
    setDocType(documentType);
    if (documentType === "other") {
      hiddenUploadInput.current?.click();
    } else {
      setOpenAddAgreementModal(true);
    }
    setOpenSelectDocModal();
  };

  const getAppointments = () => dispatch(actions.getAppointments(person.data.attributes.uuid));

  const agreementCloseModal = () => {
    setOpenAddAgreementModal(false);
    getAppointments();
  };

  const closeSelectDocModal = () => {
    setOpenAgreementTypeModal(false);
    getAppointments();
  };

  return (
    <div className="tw-overflow-auto tw-h-full tw-relative">
      <AgreementsConfirmModal
        show={openAgreementModalConfirmation}
        handleHide={() => {
          getAppointments();
          setOpenAgreementModalConfirmation(false);
        }}
        handleAddAgreement={() => {
          setOpenAgreementModalConfirmation(false);
          if (docType === "both") {
            setOpenAgreementTypeModal(true);
            return;
          }

          setDoc(docType);
        }}
      />
      <SelectDocTypeModal closeModal={closeSelectDocModal} modalOpen={openAgreementTypeModal}>
        <DocButton
          text="Buyer Agreement"
          doctype="buyer"
          Icon={KeySolidV6}
          onClickFunc={(doc) => {
            setDoc(doc);
            closeSelectDocModal();
          }}
        />
        <DocButton
          text="Seller Agreement"
          doctype="seller"
          Icon={HouseSolidV6}
          onClickFunc={(doc) => {
            setDoc(doc);
            closeSelectDocModal();
          }}
        />
      </SelectDocTypeModal>
      {successMessageOpen && (
        <div className="tw-fixed tw-left-1/2 tw-w-[226px] tw-h-[40px] tw-bg-[#EDFAF1] tw-border tw-border-solid tw-border-[#CAF0D6] tw-rounded-md tw-z-2 tw-flex tw-justify-center tw-items-center tw-gap-[8px]">
          <CheckSolidV6 size="l" className="tw-text-[#36B25F]" />
          <span className="tw-text-12d tw-text-[#177335]">Referral submitted successfully!</span>
        </div>
      )}
      {featureFlags.lenderReferral && <ReferLenderModal />}
      <div className="tw-max-w-[1650px] tw-mx-auto tw-flex tw-p-[16px] tw-gap-[16px]">
        <div id="person-details" className="tw-min-w-[280px] tw-max-w-[375px] tw-flex-1">
          <PersonInfo />
          <ContactInfo />
          <Details />
          <Relationships />
          <CardNotes />
          <Transactions newMilestoneId={newMilestoneId} />
          <Documents
            docType={docType}
            openAddAgreementModal={openAddAgreementModal}
            setDoc={setDoc}
            agreementCloseModal={agreementCloseModal}
            openSelectDocModal={openSelectDocModal}
            setOpenSelectDocModal={setOpenSelectDocModal}
            hiddenUploadInput={hiddenUploadInput}
          />
          <Projects />
        </div>

        <div className="tw-min-w-[654px] tw-flex-1 tw-flex tw-flex-col tw-gap-[16px]">
          {error && (
            <div className="tw-bg-semantic-red-5 tw-p-[12px] tw-flex tw-justify-between tw-gap-[8px]">
              <span>Something went wrong. Please try again.</span> <Button onClick={loadData}>Refresh</Button>
            </div>
          )}

          {featureFlags.gabbiAi && (
            <AiSummary
              leadPersonUuid={person.data?.attributes?.uuid}
              phoneStatus={primaryPhoneNumber?.aiStatus || "missing"}
            />
          )}

          <PersonInteractions
            allAgents={allAgents}
            gmailUnsynced={gmailUnsynced}
            emailTemplates={emailTemplates}
            appointmentOutcomes={appointmentOutcomes}
            accountMilestoneNames={accountMilestoneNames}
            setOpenAddAgreementModal={(type) => {
              setDocType(type);
              setOpenAgreementModalConfirmation(true);
            }}
          />

          <div className="tw-shadow-tdp-card col-xs-12">
            <TimelinesApp newDetailsPage />
          </div>
        </div>

        <div className="tw-min-w-[280px] tw-max-w-[375px] tw-flex-1">
          <AssignedTo />
          {featureFlags.lenderReferral && <Mortgage />}
          <WebActivity
            idxDataUri={idxDataUri}
            idxDataToken={idxDataToken}
            openPlatformCTA={openPlatformCTA}
          />
          <Agreements />
          <CardAppointments allAgents={allAgents} accountMilestoneNames={accountMilestoneNames} />
          <HomeApp />
          <CardTasks
            accountMilestoneNames={accountMilestoneNames}
            allAgents={allAgents}
            setOpenAddAgreementModal={(type) => {
              setDocType(type);
              setOpenAgreementModalConfirmation(true);
            }}
          />
          <AutoPlans openPlatformCTA={openPlatformCTA} />
          <ListingAlertCard openPlatformCTA={openPlatformCTA} />
          <MarketReports
            refreshKey={state.marketReportsRefreshKey}
            editMarketReport={(id) => editMarketReport(id)}
            editMarketReportPanel2={openPanel2}
            openMarketReport={() => toggleAddMarketModal()}
          />
          <CmaReports />
        </div>

        <PersonDetailsModal
          show={editPersonModalOpen}
          personId={person.data?.attributes.id}
          modalType={
            ["Agent", "Administrator", "Account Owner"].includes(person?.personType) ? "user" : "lead"
          }
          onClose={() => {
            dispatch(actions.closeEditPersonModal());
            dispatch(actions.getPerson(person.data?.attributes.slug));
          }}
        />

        <HubspotRequestDemoModal show={state.platformCTAOpen} closeModal={closePlatformCTA} />
        {state.showAddModal && (
          <AddMarketReportModal
            person={marketReportPerson}
            isOpen
            close={toggleAddMarketModal}
            primaryAgent={person.data?.attributes.primary_agent_uuid}
            id={state.editMarketReportId}
            scrollForm2={state.scrollForm2}
          />
        )}
        <ContactMergeModal push={push} />
      </div>
    </div>
  );
};

PersonDetail.propTypes = {
  allAgents: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  newMilestoneId: PropTypes.number.isRequired,
  gmailUnsynced: PropTypes.bool.isRequired,
  emailTemplates: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  appointmentOutcomes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  accountMilestoneNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  idxDataToken: PropTypes.string.isRequired,
  idxDataUri: PropTypes.string.isRequired,
};

export default PersonDetail;
